import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import cryptoTableReducer from './cryptoTableReducer';
import newOrderTableReducer from './newOrderTableReducer';
import customizerReducer from './customizerReducer';
import costCentersReducer from './costCenterReducers'
import paymentAccountsReducer from './paymentAccountReducer'
import loginCompanyReducer from './loginCompanyReducer'
import { reducer as reduxFormReducer } from 'redux-form';
import {combineReducers} from 'redux';
import participantReducer from './participantReducer';
import tokenReducer from './tokenReducer';
import tripReducer from './tripReducer';
import rulesReducer from './rulesReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  cryptoTable: cryptoTableReducer,
  newOrder: newOrderTableReducer,
  customizer: customizerReducer,
  currentUser: loginCompanyReducer,
  paymentAccounts :paymentAccountsReducer,
  costCenters: costCentersReducer,
  participants: participantReducer,
  token: tokenReducer,
  trip: tripReducer,
  rules: rulesReducer,
  dashboard: dashboardReducer
});
export default rootReducer;
