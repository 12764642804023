import React, { PureComponent } from 'react';
import classNames from 'classnames';
// import PropTypes from 'prop-types';
// import Loader from '../../shared/components/Loader';

class MainWrapper extends PureComponent {
	render() {
		const wrapperClass = classNames({
			wrapper: true,
			'squared-corner-theme': false,
			'blocks-with-shadow-theme': true,
			'top-navigation': true,
		});

		return (
			<div className="theme-light">
				<div className={wrapperClass}>{this.props.children}</div>
			</div>
		);
	}
}

export default MainWrapper;
