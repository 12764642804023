import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AwesomeTable from '../../../../shared/components/table/AwesomeTable';
import Modal from './ModalDestinations';

import { Card, CardBody, Col } from 'reactstrap';
import { translate } from 'react-i18next';

import heads from './heads';
import formatTrips from '../formatTrips';


const TripsTable = ({date_start, date_end, trips, tripsAreLoading, fetchCompanyTrips }) => {
	const [rows, setRows] = useState([]);
	const [date, setDate] = useState({
		start: date_start,
		end: date_end
	});

	const [modalUp, setModalUp] = useState(false);
	const [modalData, setModalData] = useState({});

	const handleToggle = useCallback(() => setModalUp(currentState => !currentState), []);

	const handleModal = useCallback(item => {
		setModalUp(true);
		setModalData(item);
	}, []);

	useEffect(() => {
		setRows(formatTrips(trips.filter(t => t.is_trip_completed == 1)).reverse());
	}, [trips]);

	function fetchCompanyTripsByDate(e) {
		e.preventDefault();
		fetchCompanyTrips('', date);
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<AwesomeTable
						heads={heads}
						rows={rows}
						date
						loading={tripsAreLoading}
						limit={1000}
						generateCsv
						onItemClick={handleModal}
						emptyMessage="Não existem viagens registradas"
					/>
				</CardBody>
			</Card>

			<Modal isOpen={modalUp} item={modalData} toggle={handleToggle} />
		</React.Fragment>
	);
};

TripsTable.propTypes = {
	t: PropTypes.func.isRequired,
	trips: PropTypes.array.isRequired,
};

export default translate('common')(TripsTable);
