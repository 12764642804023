import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import AwesomeTable from '../../../../shared/components/table/AwesomeTable';
import Modal from './ModalDestinations';

import { Card, CardBody, Col } from 'reactstrap';
import { translate } from 'react-i18next';

import heads from './heads';
import formatTrips from '../formatTrips';
import moment from 'moment';

const TripsTable = ({ t, trips, tripsAreLoading, tripsHasErrored, fetchCompanyTrips }) => {
	const [rows, setRows] = useState([]);
	const [date, setDate] = useState({
		start: moment().startOf('isoWeek').format('Y-MM-DD'),
		end: moment().format('Y-MM-DD'),
	});

	const [modalUp, setModalUp] = useState(false);
	const [modalData, setModalData] = useState({});

	const handleToggle = useCallback(() => setModalUp(currentState => !currentState), []);

	const handleModal = useCallback(item => {
		setModalUp(true);
		setModalData(item);
	}, []);

	useEffect(() => {
		setRows(formatTrips(trips));
	}, [trips]);

	function fetchCompanyTripsByDate(e) {
		e.preventDefault();
		fetchCompanyTrips('', date);
	}

	function updateStartDate(e) {
		// console.log(e.target.value);
		setDate({ ...date, start: e.target.value });
	}

	function updateEndDate(e) {
		setDate({ ...date, end: e.target.value });
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<AwesomeTable
						filter
						heads={heads}
						rows={rows}
						date
						onStartDateChange={updateStartDate}
						onEndDateChange={updateEndDate}
						onSubmitDate={fetchCompanyTripsByDate}
						loading={tripsAreLoading}
						limit={10}
						generateCsv
						onItemClick={handleModal}
						emptyMessage="Não existem viagens registradas"
					/>
				</CardBody>
			</Card>

			<Modal isOpen={modalUp} item={modalData} toggle={handleToggle} />
		</React.Fragment>
	);
};

TripsTable.propTypes = {
	t: PropTypes.func.isRequired,
	trips: PropTypes.array.isRequired,
};

export default translate('common')(TripsTable);
