import {
  FETCH_COMPANY_TRIPS_SUCCESS,
  FETCH_COMPANY_TRIPS_IS_LOADING,
  FETCH_COMPANY_TRIPS_HAS_ERRORED
} from '../actionTypes';

const initialState = {
  trips: [],
  isLoading: false,
  hasErrored: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_TRIPS_SUCCESS:
      return {
        ...state,
        trips: action.trips,
        isLoading: false,
        hasErrored: false
      }
    case FETCH_COMPANY_TRIPS_IS_LOADING:
      return {
        ...state,
        trips: [],
        isLoading: true,
        hasErrored: false
      }
    case FETCH_COMPANY_TRIPS_HAS_ERRORED:
      return {
        ...state,
        trips: [],
        isLoading: false,
        hasErrored: true
      }
    default:
      return state;
  }
}
