import moment from 'moment';

/*
  Below this single line
  the only things that you will encounter is
  fear, hate and despair

  I hope the best to you, and I'm really looking forward
  so you can rewrite this in a better way

  xoxo <3
*/

const makeDateReadable = date => moment(date).format('DD/MM/Y H:mm');

const getTotal = n => n.toFixed(2).replace('.', ',');

const getPaymentMode = id => {
	switch (id) {
		case 0:
		case 2:
			return 'Crédito';
		case 3:
			return 'Token';
		default:
			return 'Dinheiro';
	}
};

export const getTripStatus = id => {
	const types = {
		0: 'Corrida criada',
		1: 'Corrida aceita pelo entregador',
		2: 'Corrida iniciada pelo entregador',
		4: 'Entregador chegou',
		6: 'Corrida iniciada',
		8: 'Voltando à origem',
		9: 'Finalizada',
		default: 'N/A',
	};
	return types[id] || types.defalt;
};

const makeTrips = trips => {
	return trips.map(
		({
			unique_id,
			type_name,
			user_first_name,
			user_last_name,
			provider_first_name,
			provider_last_name,
			source_address,
			destination_address,
			created_at,
			provider_trip_start_time,
			provider_trip_end_time,
			payment_mode,
			total_for_company,
			is_trip_cancelled,
			is_trip_cancelled_by_provider,
			destinations,
			total_distance,
			status,
			cost_center,
			provider
		}) => {
			const statusFormated = !!is_trip_cancelled
				? !!is_trip_cancelled_by_provider
					? 'Cancelada - Entregador'
					: 'Cancelada - Lojista'
				: getTripStatus(status);

			return {
				id: unique_id,
				confirmation: makeDateReadable(created_at),
				type: type_name,
				passenger: `${user_first_name} ${user_last_name}`,
				address: (cost_center ? cost_center.name : source_address),
				provider: (provider ? `${provider.first_name} ${provider.last_name}`  : `${provider_first_name} ${provider_last_name}`),
				destination: destinations.length,
				start: makeDateReadable(provider_trip_start_time),
				end: makeDateReadable(provider_trip_end_time),
				payment: getPaymentMode(payment_mode),
				total_for_company: total_for_company ? getTotal(total_for_company) : '-',
				status: statusFormated,
				data: {
					unique_id,
					created_at,
					user_first_name,
					user_last_name,
					provider_first_name,
					provider_last_name,
					source_address,
					destination_address,
					total_distance,
					is_trip_cancelled,
					total_for_company,
					provider_trip_start_time,
					provider_trip_end_time,
					destinations,
				},
			};
		}
	);
};

export default makeTrips;
