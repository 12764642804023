import {
  LOAD_COST_CENTERS,
  ADD_COST_CENTER,
  SET_COST_CENTER,
  CREATE_COST_CENTER_HAS_ERRORED,
  RESET_COST_CENTER_ERRORS,
} from '../actionTypes';

const DEFAULT_COST_CENTER = {
  costCenters: [],
  costCenter: {},
  hasErrored: false,
  error: {},
};

export default (state = DEFAULT_COST_CENTER, action) => {
  switch (action.type) {
    case LOAD_COST_CENTERS:
      return {
        ...state,
        costCenters: action.costCenters,
        hasErrored: false,
        error: {},
        addCostCenterSuccess: false,
      };
    case ADD_COST_CENTER:
      return {
        ...state,
        costCenters: [...state.costCenters, action.costCenter],
        hasErrored: false,
        error: {},
        addCostCenterSuccess: true,
      };
    case SET_COST_CENTER:
      return {
        ...state,
        costCenter: action.costCenter,
        hasErrored: false,
        error: {},
        addCostCenterSuccess: false,
      };
    case CREATE_COST_CENTER_HAS_ERRORED:
      return {
        ...state,
        hasErrored: true,
        error: action.error,
        addCostCenterSuccess: false,
      };
    case RESET_COST_CENTER_ERRORS:
      return {
        ...state,
        addCostCenterSuccess: false,
        error: {},
        hasErrored: false,
      };
    default:
      return state;
  }
};
