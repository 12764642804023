import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

export const AuthContext = React.createContext({});

class AuthProvider extends React.PureComponent {
	queue = [];

	initialState = {
		token: null,
		user: null,
		isLoggedIn: false,
		isLoading: false,
		cities: []
	};

	state = this.initialState;

	componentDidMount() {
		this.hasValidToken();
		this.fetchCities();
	}

	_addToQueue = func => {
		this.queue.push(func);
	};

	_runQueue = () => {
		console.log('Running Authentication queue', this.queue.length);
		this.queue.forEach(func => func());
		setTimeout(() => (this.queue = []), 2000);
	};

	_executeOnlyIfAuthenticated = fn => {
		return (...args) => {
			const functionToBeExecuted = fn.bind(this, ...args);
			if (this.state.isLoggedIn) functionToBeExecuted();
			else this._addToQueue(functionToBeExecuted);
		};
	};

	login = async (email, password) => {
		const { history } = this.props;
		const request = this.request();
		const url = 'api/company/v1/company_admins/signin';

		try {
			const response = await request('POST', url, { email, password });
			const { success, data } = response.data;

			if (success && data.user) {
				localStorage.setItem('jwtToken', data.token);

				this.setState(
					{
						user: data.user,
						token: data.token,
						isLoggedIn: true,
						isLoading: false,
					},
					() => {
						history.push('/dashboard_default');
					}
				);
			}else{
				toast.warn(data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	fetchCities = async () => {
		const request = this.request();
		try {
			const response = await request('GET', `api/company/v1/cities`);
			this.setState({
				cities: (response && response.data && response.data.data) || []
			});
		} catch (err) {
			this.setState({
				cities: []
			});
		}
	};

	register = async (form) => {
		const request = this.request();
		const url = 'api/company/v1/company_admins/signup';

		try {
			const response = await request('POST', url, form);
			return response.data.success;
		} catch (error) {
			return false;
		}
	};

	logout = () => {
		const { history } = this.props;
		this.setState(this.initialState, () => {
			history.push('/log_in');
			localStorage.clear();
		});
	};

	hasValidToken = async () => {
		const token = localStorage.jwtToken;

		if (!token) return;

		const request = this.request(token);

		try {
			const response = await request('GET', 'api/company/v1/company_admins/me');
			const { success, data } = response.data;
			if (success) {
				this.setState(
					{
						token,
						user: data,
						isLoggedIn: true,
						isLoading: false,
					},
					this._runQueue
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	request = savedToken => {
		const { host } = this.props;
		let token = savedToken || this.state.token;

		return async (method, url, data) => {
			if (!token && this.state.token) {
				token = this.state.token;
			}

			const headers = {
				authorization: `JWT ${token}`,
			};

			const options = { method, headers };
			const route = new URL(`${host}${url}`);

			if (method === 'GET' && data) {
				Object.keys(data).forEach(key => {
					route.searchParams.append(key, data[key]);
				});
			} else {
				options.data = data;
			}

			const requestPromise = axios({
				url: route.href,
				method,
				...options,
			});

			return requestPromise;
		};
	};

	render() {
		const value = {
			...this.state,
			runQueue: this._runQueue,
			addToQueue: this._addToQueue,
			executeOnlyIfAuthenticated: this._executeOnlyIfAuthenticated,
			login: this.login,
			register: this.register,
			logout: this.logout,
			request: this.request(),
			hasValidToken: this.hasValidToken,
			fetchCities: this.fetchCities
		};

		return <AuthContext.Provider value={value}>{this.props.children}</AuthContext.Provider>;
	}
}

export default withRouter(AuthProvider);
