import React from 'react';
import PaymentAccountInfo from './PaymentAccountInfo';
import CostCenters from './CostCenters';
import { BillingContext } from '../../../../context/Billing';

class CostCenterList extends React.Component {
	static contextType = BillingContext;

	componentDidMount() {
		const { paymentAccountDetailsAreLoading } = this.context;

		if (!paymentAccountDetailsAreLoading && !this.context.selectedPaymentAccount) {
			// fetchDefaultPaymentAccount();
		}
	}

	render() {
		return (
			<CostCenters />
		);
	}
}

export default CostCenterList;
