import React, {useContext, useState, setState} from 'react';
import { withRouter } from 'react-router';
import { BillingContext } from '../../../../context/Billing';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, } from 'reactstrap';
import { enableNotification } from '../../../../shared/notify';
const notify = enableNotification('full');

const CostCenterCard = ({ id, name, address, phone, createdAt, onClick, history }) => {
	return (
		<div className="cost-center-card">
			<div className="cost-center-card__name">
				<span>{name}</span>
			</div>

			<div className="cost-center-card__creation" style={{minHeight:"90px"}}>
				<div>{address}</div>
				<div style={{marginTop: 10}}>{phone}&nbsp;</div> 
			</div>

			<div className="cost-center-card__details">
				<ModalRemove buttonLabel="Remover" costCenterId = {id} costCenterName={name}/>
			</div>
		</div>
	);
};

const ModalRemove = (props) => {
	const {
	  buttonLabel,
	  className,
	  costCenterName,
	  costCenterId
	} = props;

	const [modal, setModal] = useState(false);
	const {removeCostCenter} = useContext(BillingContext);
	const toggle = () => setModal(!modal);

	const remove = () => {
		removeCostCenter(costCenterId);
		notify("Loja removida!", 'success');
	}

	return (
	  <>
		<Button style={{margin: '0px 5px'}} color="danger" onClick={toggle}>{buttonLabel}</Button>
		<Modal isOpen={modal} toggle={toggle} className={className} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
		  <ModalHeader toggle={toggle}>Remover loja</ModalHeader>
		  <ModalBody>
			Deseja remover <strong>{costCenterName}</strong> da lista de lojas?
		  </ModalBody>
		  <ModalFooter>
			<Button color="danger" onClick={() => { remove(); toggle()}}>Remover</Button>
			<Button color="secondary" onClick={toggle}>Cancelar</Button>
		  </ModalFooter>
		</Modal>
	  </>
	);
}

export default withRouter(CostCenterCard);
