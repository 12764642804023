export default function objectMatrixToCsv(array) {
  let csv = "data:text/csv;charset=utf-8,"

  const getValues = item =>
    Object.values(item).join(";") + "\n"

  if(array.length > 0) {
    const heads = Object.keys(array[0])
    csv += heads.join(";") + "\n"

    array.forEach(item => {
      csv += getValues(item)
    })
  }

  return csv
}
