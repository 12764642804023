import React from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

const ModalParticipant = props => {
  const { participant, cadastrar, modal, handleModal } = props;

  return (
    <Modal isOpen={modal} toggle={handleModal}>
      <ModalHeader toggle={handleModal}>Confirmar Cadastro?</ModalHeader>
      <ModalBody>
        {participant && (
          <img
            src={`https://api.moobi.live/${participant.picture}`}
            alt="imagem de perfil"
          />
        )}
        <h5>
          {participant && `${participant.first_name} ${participant.last_name}`}
        </h5>
        <h5>{participant && `${participant.email}`}</h5>
        <h5>{participant && `${participant.city}`}</h5>
      </ModalBody>
      <ModalFooter>
        {props.register ? (
          <Button color="primary" onClick={cadastrar}>
            Confirmar
          </Button>
        ) : null}
        <Button color="secondary" onClick={handleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalParticipant;
