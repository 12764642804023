import React from 'react';
import { AuthContext } from './Auth';

export const DashboardContext = React.createContext({});

class DashboardProvider extends React.PureComponent {
	static contextType = AuthContext;

	initialState = {
		participants: {
			list: [],
			total: 0,
			isLoading: false,
			hasErrored: false,
		},
		trips: {
			list: [],
			labels: [],
			total: 0,
			isLoading: false,
			hasErrored: false,
		},
		tripsWeek: {
			list: [],
			labels: [],
			total: 0,
			isLoading: false,
			hasErrored: false,
		},
		tripsMonth: {
			list: [],
			labels: [],
			total: 0,
			isLoading: false,
			hasErrored: false,
		},
		expenses: {
			list: [],
			total: 0,
			isLoading: false,
			hasErrored: false,
		},
		costCenterCosumption: {
			list: [],
			maxValue: 0,
			isLoading: false,
			hasErrored: false,
		},
		topProviders: {
			list: [],
			isLoading: false,
			hasErrored: false,
		},
	};

	state = this.initialState;

	getParticipants = () => {
		const {
			request,
			user: { company },
		} = this.context;

		const url = `api/company/v1/companies/${company}/dashboard/participants/`;

		this.setState(
			{
				participants: {
					list: [],
					total: 0,
					isLoading: true,
					hasErrored: false,
				},
			},
			async () => {
				try {
					const response = await request('GET', url);
					const { success, data } = response.data;
					if (success) {
						this.setState({
							participants: {
								list: data.participants,
								total: data.total,
								isLoading: false,
								hasErrored: false,
							},
						});
					}
				} catch (error) {
					this.setState({
						participants: {
							list: [],
							total: 0,
							isLoading: false,
							hasErrored: true,
						},
					});
					console.log(error);
				}
			}
		);
	};

	getTrips = () => {
		const {
			request,
			user: { company },
		} = this.context;

		const url = `api/company/v1/companies/${company}/dashboard/trips/day/`;

		this.setState(
			{
				trips: {
					list: [],
					labels: [],
					total: 0,
					isLoading: true,
					hasErrored: false,
				},
			},
			async () => {
				try {
					const response = await request('GET', url);
					const { success, data } = response.data;
					if (success) {
						this.setState({
							trips: {
								list: data.datasets,
								labels: data.labels,
								total: data.total,
								isLoading: false,
								hasErrored: false,
							},
						});
					}
				} catch (error) {
					this.setState({
						trips: {
							list: [],
							labels: [],
							total: 0,
							isLoading: false,
							hasErrored: true,
						},
					});
					console.log(error);
				}
			}
		);
	};

	getTripsWeek = () => {
		const {
			request,
			user: { company },
		} = this.context;

		const url = `api/company/v1/companies/${company}/dashboard/trips/week/`;

		this.setState(
			{
				tripsWeek: {
					list: [],
					labels: [],
					total: 0,
					isLoading: true,
					hasErrored: false,
				},
			},
			async () => {
				try {
					const response = await request('GET', url);
					const { success, data } = response.data;
					if (success) {
						this.setState({
							tripsWeek: {
								list: data.datasets,
								labels: data.labels,
								total: data.total,
								isLoading: false,
								hasErrored: false,
							},
						});
					}
				} catch (error) {
					this.setState({
						trips: {
							list: [],
							labels: [],
							total: 0,
							isLoading: false,
							hasErrored: true,
						},
					});
					console.log(error);
				}
			}
		);
	};

	getTripsMonth = () => {
		const {
			request,
			user: { company },
		} = this.context;

		const url = `api/company/v1/companies/${company}/dashboard/trips/month/`;

		this.setState(
			{
				tripsMonth: {
					list: [],
					labels: [],
					total: 0,
					isLoading: true,
					hasErrored: false,
				},
			},
			async () => {
				try {
					const response = await request('GET', url);
					const { success, data } = response.data;
					if (success) {
						this.setState({
							tripsMonth: {
								list: data.datasets,
								labels: data.labels,
								total: data.total,
								isLoading: false,
								hasErrored: false,
							},
						});
					}
				} catch (error) {
					this.setState({
						trips: {
							list: [],
							labels: [],
							total: 0,
							isLoading: false,
							hasErrored: true,
						},
					});
					console.log(error);
				}
			}
		);
	};

	getExpenses = () => {
		const {
			request,
			user: { company },
		} = this.context;

		const url = `api/company/v1/companies/${company}/dashboard/expenses/`;

		this.setState(
			{
				expenses: {
					list: [],
					total: 0,
					isLoading: true,
					hasErrored: false,
				},
			},
			async () => {
				try {
					const response = await request('GET', url);
					const { success, data } = response.data;
					if (success) {
						this.setState({
							expenses: {
								list: data.expenses,
								total: data.total,
								isLoading: false,
								hasErrored: false,
							},
						});
					}
				} catch (error) {
					this.setState({
						expenses: {
							list: [],
							total: 0,
							isLoading: false,
							hasErrored: true,
						},
					});
					console.log(error);
				}
			}
		);
	};

	getCostCenterCosumption = () => {
		const {
			request,
			user: { company },
		} = this.context;

		const url = `api/company/v1/companies/${company}/dashboard/cost-center-cosumption/`;

		this.setState(
			{
				costCenterCosumption: {
					list: [],
					maxValue: 0,
					isLoading: true,
					hasErrored: false,
				},
			},
			async () => {
				try {
					const response = await request('GET', url);
					const { success, data } = response.data;
					console.log(response.data);
					if (success) {
						this.setState({
							costCenterCosumption: {
								list: data.data,
								maxValue: data.max_value,
								isLoading: false,
								hasErrored: false,
							},
						});
					}
				} catch (error) {
					this.setState({
						costCenterCosumption: {
							list: [],
							maxValue: 0,
							isLoading: false,
							hasErrored: true,
						},
					});
					console.log(error);
				}
			}
		);
	};

	getTopProviders = () => {
		const {
			request,
			user: { company },
		} = this.context;

		const url = `api/company/v1/companies/${company}/dashboard/top-providers`;

		this.setState(
			{
				topProviders: {
					list: [],
					isLoading: true,
					hasErrored: false,
				},
			},
			async () => {
				try {
					const response = await request('GET', url);
					const { success, data } = response.data;
					if (success) {
						this.setState({
							topProviders: {
								list: data,
								isLoading: false,
								hasErrored: false,
							},
						});
					}
				} catch (error) {
					this.setState({
						topProviders: {
							list: [],
							isLoading: false,
							hasErrored: true,
						},
					});
					console.log(error);
				}
			}
		);
	};

	render() {
		const value = {
			state: { ...this.state },
			action: {
				getParticipants: this.getParticipants,
				getTrips: this.getTrips,
				getTripsWeek: this.getTripsWeek,
				getTripsMonth: this.getTripsMonth,
				getExpenses: this.getExpenses,
				getCostCenterCosumption: this.getCostCenterCosumption,
				getTopProviders: this.getTopProviders,
			},
		};

		return <DashboardContext.Provider value={value}>{this.props.children}</DashboardContext.Provider>;
	}
}

export default DashboardProvider;
