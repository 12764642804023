import React from "react"
import NotificationSystem from "rc-notification";

import {
  BasicNotification,
  ImageNotification,
  FullWideNotification
} from "./components/Notification";

/*
  Basic
  Image
  Full
*/

export const enableNotification = (notificationType) => {
  let Component

  switch (notificationType) {
    case "basic":
      Component = BasicNotification
      break
    case "image":
      Component = ImageNotification
      break
    case "full":
      Component = FullWideNotification
      break
    default:
      return
  }

  return (message, color) => {
    NotificationSystem.newInstance({}, n => {
      n.notice({
        content: <Component color={color} message={message} />,
        duration: 1.2,
        closable: true,
        style: { top: 0, left: "calc(100vw - 100%)" },
        className: "right-up"
      });
    });
  };
};

export const fullNotification = enableNotification("full")

export const basicNotification = enableNotification("basic")

export const imageNotification = enableNotification("image")
