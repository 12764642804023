import {
  SET_CURRENT_USER,
  GET_CURRENT_USER_DATA_ERROR,
  CURRENT_USER_IS_LOADING,
  LOGOUT
} from '../actionTypes';

const DEFAULT_STATE = {
    isAuthenticated: false,
    user: {},
    hasErrored: false,
    isLoading: true
}

export default (state = DEFAULT_STATE, action) => {
    switch(action.type) {
        case CURRENT_USER_IS_LOADING:
          return {
            ...state,
            isAuthenticated: false,
            user: {},
            hasErrored: false,
            isLoading: action.bool
          }
        case SET_CURRENT_USER:
          return {
            ...state,
            isAuthenticated: true,
            user: action.user,
            hasErrored: false,
            isLoading: false
          }
        case GET_CURRENT_USER_DATA_ERROR:
          return {
            ...state,
            isAuthenticated: false,
            user: {},
            hasErrored: action.bool,
            isLoading: false
          }
        case LOGOUT:
          return {
            ...state,
            isAuthenticated: false,
            user: {},
            hasErrored: false,
            isLoading: false
          }
        default:
            return state;
    }
}
