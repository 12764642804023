import React, { useContext, useEffect } from 'react';
import { DashboardContext } from '../../../../context/Dashboard';
import Panel from '../../../../shared/components/Panel';
import { translate } from 'react-i18next';
import { Line, Bar } from 'react-chartjs-2';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const options = {
	maintainAspectRatio: false,
};

const Trips = ({ t }) => {
	const {
		state: { trips, tripsWeek, tripsMonth },
		action: { getTrips, getTripsWeek, getTripsMonth },
	} = useContext(DashboardContext);

	useEffect(() => {
		getTrips();
		getTripsWeek();
		getTripsMonth();
	}, []);

	return (
		<Col md={12}>
			<Row>
				<Panel md={12} title={'Entregas por dia - em 30 dias'}>
					<Row>
						<Line
							data={{
								labels: trips.labels,
								datasets: trips.list,
							}}
							height={200}
							options={options}
						/>
					</Row>
				</Panel>
				<Panel md={7} title={'Entregas por semana - em 2 meses'}>
					<Row>
						<Bar
							data={{
								labels: tripsWeek.labels,
								datasets: tripsWeek.list,
							}}
							height={300}
							options={options}
						/>
					</Row>
				</Panel>
				<Panel md={5} title={'Entregas por mês - em 6 meses'}>
					<Row>
						<Bar
							data={{
								labels: tripsMonth.labels,
								datasets: tripsMonth.list,
							}}
							height={300}
							options={options}
						/>
					</Row>
				</Panel>
			</Row>
		</Col>
	);
};

Trips.propTypes = {
	t: PropTypes.func.isRequired,
};

export default translate('common')(Trips);
