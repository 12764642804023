export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_PARTICIPANT = 'SET_PARTICIPANT';
export const REMOVE_PARTICIPANTS = 'REMOVE_PARTICIPANTS';
export const ADD_PAYMENT_ACCOUNT = 'ADD_PAYMENT_ACCOUNT';
export const LOAD_PAYMENT_ACCOUNTS = 'LOAD_PAYMENT_ACCOUNTS';
export const SET_PAYMENT_ACCOUNT = 'SET_PAYMENT_ACCOUNT';
export const RESET_PAYMENT_ACCOUNT_ERRORS = 'RESET_PAYMENT_ACCOUNT_ERRORS';
export const CREATE_PAYMENT_ACCOUNT_HAS_ERRORED = 'CREATE_PAYMENT_ACCOUNT_HAS_ERRORED';
export const SET_COST_CENTER = 'SET_COST_CENTER';
export const LOAD_COST_CENTERS = 'LOAD_COST_CENTERS';
export const ADD_COST_CENTER = 'ADD_COST_CENTER';
export const CREATE_COST_CENTER_HAS_ERRORED = 'CREATE_COST_CENTER_HAS_ERRORED';
export const RESET_COST_CENTER_ERRORS = 'RESET_COST_CENTER_ERRORS';
export const LOAD_PARTICIPANTS = 'LOAD_PARTICIPANTS';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const LOAD_PAYMENT_TOKENS = 'LOAD_PAYMENT_TOKENS';
export const ADD_PAYMENT_TOKEN = 'ADD_PAYMENT_TOKEN';
export const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN';
export const GET_CURRENT_USER_DATA_ERROR = 'GET_CURRENT_USER_DATA_ERROR';
export const CURRENT_USER_IS_LOADING = 'CURRENT_USER_IS_LOADING';
export const FETCH_PAYMENT_TOKENS_IS_LOADING = 'FETCH_PAYMENT_TOKENS_IS_LOADING';
export const FETCH_PAYMENT_TOKENS_HAS_ERRORED = 'FETCH_PAYMENT_TOKENS_HAS_ERRORED';
export const FETCH_PAYMENT_TOKENS_SUCCESS = 'FETCH_PAYMENT_TOKENS_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FETCH_COMPANY_TRIPS_IS_LOADING = 'FETCH_COMPANY_TRIPS_IS_LOADING';
export const FETCH_COMPANY_TRIPS_HAS_ERRORED = 'FETCH_COMPANY_TRIPS_HAS_ERRORED';
export const FETCH_COMPANY_TRIPS_SUCCESS = 'FETCH_COMPANY_TRIPS_SUCCESS';
export const FETCH_RULES_IS_LOADING = 'FETCH_RULES_IS_LOADING';
export const FETCH_RULES_HAS_ERRORED = 'FETCH_RULES_HAS_ERRORED';
export const FETCH_RULES_SUCCESS = 'FETCH_RULES_SUCCESS';

export const FETCH_ALL_PARTICIPANTS = 'FETCH_ALL_PARTICIPANTS';
export const FETCH_COST_PARTICIPANTS = 'FETCH_COST_PARTICIPANTS';
export const FETCH_PAYMENT_PARTICIPANTS = 'FETCH_PAYMENT_PARTICIPANTS';

// Dashboard Stuff (too long to indent)
export const FETCH_DASHBOARD_PARTICIPANTS = 'FETCH_DASHBOARD_PARTICIPANTS';
export const FETCH_DASHBOARD_PARTICIPANTS_HAS_ERRORED = 'FETCH_DASHBOARD_TRIPS_HAS_ERRORED';
export const FETCH_DASHBOARD_PARTICIPANTS_IS_LOADING = 'FETCH_DASHBOARD_TRIPS_IS_LOADING';
export const FETCH_DASHBOARD_TRIPS = 'FETCH_DASHBOARD_TRIPS';
export const FETCH_DASHBOARD_TRIPS_HAS_ERRORED = 'FETCH_DASHBOARD_TRIPS_HAS_ERRORED';
export const FETCH_DASHBOARD_TRIPS_IS_LOADING = 'FETCH_DASHBOARD_TRIPS_IS_LOADING';
export const FETCH_DASHBOARD_EXPENSES = 'FETCH_DASHBOARD_EXPENSES';
export const FETCH_DASHBOARD_EXPENSES_HAS_ERRORED = 'FETCH_DASHBOARD_EXPENSES_HAS_ERRORED';
export const FETCH_DASHBOARD_EXPENSES_IS_LOADING = 'FETCH_DASHBOARD_EXPENSES_IS_LOADING';
export const FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES = 'FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES';
export const FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_HAS_ERRORED =
	'FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_HAS_ERRORED';
export const FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_IS_LOADING =
	'FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_IS_LOADING';
export const FETCH_DASHBOARD_COST_CENTER_COSUMPTION = 'FETCH_DASHBOARD_COST_CENTER_COSUMPTION';
export const FETCH_DASHBOARD_COST_CENTER_COSUMPTION_HAS_ERRORED = 'FETCH_DASHBOARD_COST_CENTER_COSUMPTION_HAS_ERRORED';
export const FETCH_DASHBOARD_COST_CENTER_COSUMPTION_IS_LOADING = 'FETCH_DASHBOARD_COST_CENTER_COSUMPTION_IS_LOADING';
export const FETCH_DASHBOARD_TOP_PROVIDERS = 'FETCH_DASHBOARD_TOP_PROVIDERS';
export const FETCH_DASHBOARD_TOP_PROVIDERS_IS_LOADING = 'FETCH_DASHBOARD_TOP_PROVIDERS_IS_LOADING';
export const FETCH_DASHBOARD_TOP_PROVIDERS_HAS_ERRORED = 'FETCH_DASHBOARD_TOP_PROVIDERS_HAS_ERRORED';
