import React, { Fragment, useEffect } from "react";
import usePagination from "../hooks/pagination";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PropTypes from "prop-types";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

function controlPaginationItems(total, current, updatePage) {
  return [...Array(total)].map((page, index) => (
    <PaginationItem
      className="pagination__item"
      key={index}
      active={current === index + 1}
    >
      <PaginationLink
        className="pagination__link"
        type="button"
        onClick={() => updatePage(index + 1)}
      >
        {index + 1}
      </PaginationLink>
    </PaginationItem>
  ));
}

const PaginationMaster = props => {
  const {
    currentElements,
    current,
    total,
    size,
    elements,
    updateCurrentPage,
    updateElements,
    updateMaxElements
  } = usePagination(1, props.rows, props.limit);

  useEffect(() => {
    updateElements(props.rows);
  }, [props.rows]);

  return (
    <Fragment>
      {props.children(currentElements, updateCurrentPage, updateMaxElements)}
      <div className="pagination__wrap">
        {!total || props.omit ? (
          ""
        ) : (
          <Pagination className="pagination">
            <PaginationItem
              className="pagination__item"
              disabled={current === 1}
            >
              <PaginationLink
                className="pagination__link pagination__link--arrow"
                type="button"
                onClick={() => updateCurrentPage(current - 1)}
              >
                <ChevronLeftIcon className="pagination__link-icon" />
              </PaginationLink>
            </PaginationItem>
            {controlPaginationItems(total, current, updateCurrentPage)}
            <PaginationItem
              className="pagination__item"
              disabled={current === total}
            >
              <PaginationLink
                className="pagination__link pagination__link--arrow"
                type="button"
                onClick={() => updateCurrentPage(current + 1)}
              >
                <ChevronRightIcon className="pagination__link-icon" />
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        )}
        {!props.omit && (
          <div className="pagination-info">
            <span>
              Exibindo{" "}
              {size * current > elements.length
                ? elements.length
                : size * current}{" "}
              de {elements.length}
            </span>
          </div>
        )}
      </div>
    </Fragment>
  );
};

PaginationMaster.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PaginationMaster;
