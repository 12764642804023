import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

import DefaultDashboard from '../Dashboards/Default/index';
import CostCenters from '../Pages/CostCenters';
import PageTrips from '../Pages/Trips/index';
import PageInvoices from '../Pages/Trips/invoices';
import PageInvoiceTrips from '../Pages/Trips/invoice_trips';

import LogIn from '../Account/LogIn/index';
import Register from '../Account/Register/index';

import AuthProvider from '../../context/Auth';
import DashboardProvider from '../../context/Dashboard';
import BillingProvider from '../../context/Billing';
import TokensProvider from '../../context/Tokens';
import FinancialProvider from '../../context/Financial';
import TripsProvider from '../../context/Trips';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			return localStorage.jwtToken ? <Component {...props} /> : <Redirect to="/log_in" />;
		}}
	/>
);

const AuthRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			return !localStorage.jwtToken ? <Component {...props} /> : <Redirect to="/dashboard_default" />;
		}}
	/>
);

const Pages = () => (
	<Switch>
		<Route path="/cost_centers" component={CostCenters} />

		<TripsProvider>
			<FinancialProvider>
				<Route path="/trips" component={PageTrips} />
				<Route path="/invoces" component={PageInvoices} />
				<Route path="/invoce/:date_start/:date_end" component={PageInvoiceTrips} />
			</FinancialProvider>
		</TripsProvider>
	</Switch>
);

const wrappedRoutes = () => (
	<div>
		<Layout />
		<div className="container__wrap">
			<Route path="/dashboard_default" component={DefaultDashboard} />
			<Route path="/" component={Pages} />
		</div>
	</div>
);

const Router = () => (
	<AuthProvider host={process.env.REACT_APP_BASE_URL}>
		<DashboardProvider>
			<BillingProvider>
				<TokensProvider>
					<MainWrapper>
						<main>
							<Switch>
								<AuthRoute exact path="/" component={LogIn} />
								<AuthRoute path="/log_in" component={LogIn} />
								<AuthRoute path="/register" component={Register} />
								<PrivateRoute path="/" component={wrappedRoutes} />
							</Switch>
						</main>
					</MainWrapper>
				</TokensProvider>
			</BillingProvider>
		</DashboardProvider>
		<ToastContainer />
	</AuthProvider>
);

export default Router;
