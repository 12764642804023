import React from "react";
import { Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import RegisterCostCenter from "./RegisterCostCenter";

const Register = props => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <Col md={3.5}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Adicionar Loja</h5>
            <h5 className="subhead">Novo endereço para solicitação de entregas</h5>
          </div>
          <div
            className="form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <RegisterCostCenter {...props} />
          </div>
        </CardBody>
        <Link style={{
          textAlign: `center`,
          margin: `20px 33px`,
          padding: `10px 6px`,
          border: `solid 1px #AAA`,
          borderRadius: `6px`,
          color:` #777`,
          fontWeight: `bold`,
          backgroundColor: `#DEDEDE`
        }} to={"/cost_centers"}>Cancelar</Link>
      </Card>
    </Col>
  </div>
);

export default Register;
