import React, { useContext, useEffect } from 'react';
import { DashboardContext } from '../../../../context/Dashboard';
import Panel from '../../../../shared/components/Panel';
import NumberFormat from 'react-number-format';
import { translate } from 'react-i18next';
import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';

const CostsCenters = ({ t }) => {
	const {
		state: { costCenterCosumption },
		action: { getCostCenterCosumption },
	} = useContext(DashboardContext);

	useEffect(() => {
		getCostCenterCosumption();
	}, []);

	const { list, maxValue, isLoading } = costCenterCosumption;

	return (
		<Panel
			md={7}
			title={'Lojas'}
			badge={{
				path: '/cost_centers',
				title: 'Ver lojas',
				color: 'success',
			}}
			subhead="Despesas agrupados por lojas nos ultimos 30 dias"
			refresh={isLoading}
		>
			{!!list &&
				list.map(center => (
					<div key={center.name} className="progress-wrap progress-wrap--small">
						<p>{center.name}</p>
						<Progress value={center.value} max={maxValue}>
							<NumberFormat
								prefix={'R$ '}
								value={center.value}
								displayType={'text'}
								fixedDecimalScale={true}
								decimalScale={2}
								thousandSeparator={'.'}
								decimalSeparator={','}
							/>
						</Progress>
					</div>
				))}
		</Panel>
	);
};

CostsCenters.propTypes = {
	t: PropTypes.func.isRequired,
};

export default translate('common')(CostsCenters);
