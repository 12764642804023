import StatusFormatter from './Formatter';

export default [
	{
		key: 'id',
		name: 'Id',
	},
	{
		key: 'confirmation',
		name: 'Data',
	},
	{
		key: 'address',
		name: 'Origem',
	},
	{
		key: 'provider',
		name: 'Entregador',
	},
	{
		key: 'destination',
		name: 'Entregas',
	},
	// {
	// 	key: 'start',
	// 	name: 'Início',
	// },
	// {
	// 	key: 'end',
	// 	name: 'Fim',
	// },
	// {
	// 	key: 'payment',
	// 	name: 'Pagamento',
	// },
	{
		key: 'total_for_company',
		name: 'Total',
	},
	{
		key: 'status',
		name: 'Status',
		formatter: StatusFormatter,
	},
];
