import React, { useEffect, useContext } from 'react';
import { TripsContext } from '../../../context/Trips';
import { AuthContext } from '../../../context/Auth';
import TripsTable from './components/TripsTable';

import { Container, Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';

const Trips = props => {
	const { user } = useContext(AuthContext);
	const { trips, tripsAreLoading, tripsHasErrored, fetchTrips } = useContext(TripsContext);

	useEffect(() => {
		if (!!user) fetchTrips();
	}, [user]);

	return (
		<Container>
			<Row>
				<Col md={12}>
					<h3 className="page-title">{props.t('trips.page_title')}</h3>
				</Col>
			</Row>

			<TripsTable
				trips={trips}
				tripsAreLoading={tripsAreLoading}
				tripsHasErrored={tripsHasErrored}
				fetchCompanyTrips={fetchTrips}
			/>
		</Container>
	);
};

export default translate('common')(Trips);
