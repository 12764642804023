import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import { Provider } from 'react-redux';
import { configureStore } from './containers/App/store';
import 'react-toastify/dist/ReactToastify.css';

const store = configureStore();

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);
