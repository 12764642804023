import React, { useEffect, useContext } from 'react';
import { TripsContext } from '../../../context/Trips';
import { AuthContext } from '../../../context/Auth';
import InvoicesTable from './components/InvoicesTable';

import { Container, Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import InvoicesTripsTable from './components/InvoicesTripsTable';
import moment from 'moment';
import { boletoBarcodeSvg } from "boleto-barcode-svg";
import Loader from '../../../shared/components/Loader';

const Trips = props => {
	const { user } = useContext(AuthContext);
	const { trips, tripsAreLoading, tripsHasErrored, fetchTrips, tripInvoice, fetchInvoiceTrip, tripInvoiceLoading } = useContext(TripsContext);
	const { date_start, date_end } = props.match.params;
	useEffect(() => {
		if (!!user) fetchTrips("", {
			start: date_start,
			end: date_end
		});
	}, [user]);

	useEffect(() => {
		if(trips.length) fetchInvoiceTrip(trips);
	},[trips])

	useEffect(() => {
		if(tripInvoice.length){
			if(tripInvoice[0].attachment_value != ""){
				let boletoSvg = boletoBarcodeSvg(tripInvoice[0].attachment_value);
				document.getElementById("boleto").innerHTML = boletoSvg;
			}
		}
	},[tripInvoice])

	return (
		<Container>
			<Row>
				<Col md={12}>
					<h3 className="page-title">Extrato da Fatura</h3>
				</Col>
				<Col md={4}><div style={{padding:"8px 14px", backgroundColor:"#FFF", borderRadius:6, marginBottom:16, textAlign:"center"}}>
					<div>Período</div><div style={{fontSize:22, fontWeight:600}}>{moment(date_start).format("DD/MM")} - {moment(date_end).format("DD/MM/Y")}</div></div>
				</Col>
				<Col md={4}><div style={{padding:"8px 14px", backgroundColor:"#FFF", borderRadius:6, marginBottom:16, textAlign:"center"}}>
					<div>Entregas</div><div style={{fontSize:22, fontWeight:600}}>
						{trips.filter(t => t.is_trip_completed == 1).reduce((total, item) => total + item.destinations.length,0)}
					</div></div>
				</Col>
				<Col md={4}><div style={{padding:"8px 14px", backgroundColor:"#FFF", borderRadius:6, marginBottom:16, textAlign:"center"}}>
					<div>Total</div><div style={{fontSize:22, fontWeight:600}}>
						{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(trips.filter(t => t.is_trip_completed == 1).reduce((total, item) => total + item.total_for_company,0)))}
					</div></div>
				</Col>
				{tripInvoice.length ? 
					<Col md={12}>
						<div style={{padding:"18px 14px", backgroundColor:"#FFF", borderRadius:6, marginBottom:16, textAlign:"center"}}>
							<div style={{}}>Dados para pagamento {tripInvoice.length ? (tripInvoice[0].done ? "- Pagamento Realizado" : "- Pagamento Pendente - Venc. " + moment(tripInvoice[0].due_date).format("DD/MM/Y")) : ""}</div>
							<div id="boleto" style={{padding:"10px 30px"}}></div>
							<div style={{fontSize:"2vw"}}>{tripInvoice.length ? (tripInvoice[0].attachment_value != "" ? tripInvoice[0].attachment_value : "VIA CHAVE PIX CNPJ: 32.236.865/0001-48") : "- - -"}</div>
						</div>
					</Col>
				: tripInvoiceLoading ? <Col md={12}><center><div style={{textAlign:"center"}}><Loader height={200} /></div></center></Col> : null}
			</Row>

			<InvoicesTripsTable
				date_start = {date_start}
				date_end = {date_end}
				trips = {trips}
				tripsAreLoading={tripsAreLoading}
				tripsHasErrored={tripsHasErrored}
				fetchCompanyTrips={fetchTrips}
			/>
		</Container>
	);
};

export default translate('common')(Trips);
