import React from "react";
import { AuthContext } from "./Auth";

export const TokensContext = React.createContext({});

class TokensProvider extends React.PureComponent {
  static contextType = AuthContext;

  initialState = {
    paymentTokens: [],
    paymentTokensAreLoading: false
  };

  state = this.initialState;

  _fetchPaymentTokens = async () => {
    const {
      user: { company },
      request
    } = this.context;

    this.setState({ paymentTokensAreLoading: true });

    try {
      const response = await request(
        "GET",
        `api/company/v1/companies/${company}/payment-tokens/`
      );

      if (response.data.success) {
        this.setState({
          paymentTokens: response.data.data,
          paymentTokensAreLoading: false
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchPaymentTokens = () => {
    const { isLoggedIn, addToQueue: addToAuthenticationQueue } = this.context;
    if (isLoggedIn) this._fetchPaymentTokens();
    else addToAuthenticationQueue(this._fetchPaymentTokens);
  };

  createPaymentToken = async data => {
    const {
      user: { company },
      request
    } = this.context;

    try {
      const response = await request(
        "POST",
        `api/company/v1/companies/${company}/payment-accounts/${data.paymentAccount}/cost-centers/${data.costCenter}/payment-tokens`,
        data
      );

      if (response.data.success) {
        return "Token criado com sucesso";
      }
    } catch (err) {
      return err;
    }
  };

  render() {
    const value = {
      ...this.state,
      fetchPaymentTokens: this.fetchPaymentTokens,
      createPaymentToken: this.createPaymentToken
    };

    return (
      <TokensContext.Provider value={value}>{this.props.children}</TokensContext.Provider>
    );
  }
}

export default TokensProvider;
