import axios from 'axios'

export function apiCall(method, path, data) {
    return new Promise((resolve, reject) => {
        return axios({
            method,
            url: path,
            headers: { 'content-type': 'application/json' },
            data
        })
        .then(res => {
            return resolve(res.data)
        })
        .catch(err => {
            return reject(err.response.data.error)
        })
    })
}

export function setTokenHeader(token) {
    if(token) {
        axios.defaults.headers.common["Authorization"] = `JWT ${token}`
    } else {
        delete axios.defaults.headers.common["Authorization"]
    }
}