import React, { useContext } from 'react';
import { AuthContext } from '../../../context/Auth';
import Loader from '../../../shared/components/Loader';
import Participants from './components/Participants';
import TopProviders from './components/TopProviders';
import TotalServices from './components/TotalServices';
import CostCenters from './components/CostCenters';
import Expenses from './components/Expenses';
import Trips from './components/Trips';

import { Col, Container, Row } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

const DefaultDashboard = props => {
	const { isLoggedIn } = useContext(AuthContext);

	return isLoggedIn ? (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<h3 className="page-title">{props.t('dashboard_default.page_title')}</h3>
				</Col>
			</Row>
			{/* <Row>
				<Participants />
				<TotalServices />
				<Expenses />
			</Row> */}
			<Row>
				<Trips />
				<TopProviders />
				<CostCenters />
			</Row>
		</Container>
	) : (
		<Loader />
	);
};

DefaultDashboard.propTypes = {
	t: PropTypes.func.isRequired,
};

export default translate('common')(DefaultDashboard);
