import {
  ADD_PAYMENT_TOKEN,
  SET_PAYMENT_TOKEN,
  FETCH_PAYMENT_TOKENS_IS_LOADING,
  FETCH_PAYMENT_TOKENS_HAS_ERRORED,
  FETCH_PAYMENT_TOKENS_SUCCESS
} from "../actionTypes";

const DEFAULT_STATE = {
  paymentTokens: [],
  paymentToken: {},
  isLoading: false,
  hasErrored: false
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_PAYMENT_TOKEN:
      return {
        ...state,
        paymentTokens: [...state.paymentTokens, action.newPaymentToken]
      };
    case FETCH_PAYMENT_TOKENS_SUCCESS:
      return {
        ...state,
        paymentTokens: action.tokens,
        isLoading: false,
        hasErrored: false
      };
    case SET_PAYMENT_TOKEN:
      return {
        ...state,
        paymentToken: action.paymentToken
      };
    case FETCH_PAYMENT_TOKENS_IS_LOADING:
      return {
        ...state,
        isLoading: action.bool,
        hasErrored: false
      };
    case FETCH_PAYMENT_TOKENS_HAS_ERRORED:
      return {
        ...state,
        isLoading: false,
        hasErrored: action.bool
      };
    default:
      return state;
  }
};
