import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Col,
	Table,
	Button,
	ButtonToolbar,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Form,
	FormGroup,
	Input,
} from 'reactstrap';
import PaginationMaster from '../Pagination';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import Loader from '../Loader';
import Error from '../Error';
import moment from 'moment';

import objectMatrixToCsv from '../../../lib/objectMatrixToCsv';

const Filter = ({ items, children }) => {
	const [type, setType] = useState('');
	const [search, setSearch] = useState('');
	const [filteredItems, setFilteredItems] = useState(items);

	function filterData(data) {
		const buffer = [...data];
		return buffer.sort((obj1, obj2) => {
			// console.log({ [type]: new Date(obj1[type]) });
			// if (new Date(obj1[type]) !== 'Invalid Date') {
			//   return new Date(obj1[type]) < new Date(obj2[type]) ? -1 : 1;
			// }

			switch (typeof obj1[type]) {
				case 'number':
					return obj1[type] < obj2[type] ? -1 : 1;
				case 'string':
					return obj1[type].toLowerCase() < obj2[type].toLowerCase() ? -1 : 1;
				case 'boolean':
					return obj1[type] < obj2[type] ? -1 : 1;
				default:
					return 0;
			}
		});
	}

	useEffect(() => {
		const filteredItems = items.filter( i => i.status === 'Finalizada')
		setFilteredItems(filteredItems)

		if (type) {
			setSearch('');
			setFilteredItems(filterData(items));
		}

		if (search) {
			setType('');
			setFilteredItems(
				items.filter(item => {
					for (const k of Object.values(item)) {
						console.log(k);
						if (
							k &&
							Array.isArray(k) &&
							k
								.toString()
								.toLowerCase()
								.includes(search.toLowerCase())
						) {
							return k;
						}
					}
					return false;
				})
			);
		}
	}, [items, search, type]);

	const updateType = type => setType(type);
	const updateSearch = search => setSearch(search);

	return children(filteredItems, updateSearch, updateType);
};

const FilterMenu = ({
	updateSearch,
	updateType,
	types,
	date,
	onStartDateChange,
	onEndDateChange,
	onSubmitDate,
	generateCsv,
	onGenerateCsv,
	dates
}) => {
	return (
		<div className="card__title" style={{ paddingBottom: '20px' }}>
			<div
				style={{
					float: 'left',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<ButtonToolbar>
					{generateCsv && (
						<Button color="primary" size="sm" onClick={onGenerateCsv}>
							Exportar
						</Button>
					)}
					<UncontrolledDropdown>
						<DropdownToggle className="icon icon--right" size="sm" outline>
							<p>
								Ordenar por
								<ChevronDownIcon />
							</p>
						</DropdownToggle>
						<DropdownMenu className="dropdown__menu">
							{types.map(type => (
								<DropdownItem onClick={() => updateType(type.key)} key={type.key}>
									{type.name}
								</DropdownItem>
							))}
						</DropdownMenu>
					</UncontrolledDropdown>
				</ButtonToolbar>
				{!!date && (
					<div style={{ marginLeft: 10, maxHeight: 45 }}>
						<div>
							<Form inline onSubmit={e => e.preventDefault()}>
								<FormGroup
									style={{
										marginRight: 10,
										borderRadius: '6px',
										backgroundColor: '#EEE',
										padding: '0 20px',
										display: 'flex',
										justifyContent: 'space-around',
										alignItems: 'center',
										maxHeight: 45,
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											maxHeight: 45,
										}}
									>
										<Input
											type="date"
											name="start"
											defaultValue={date.start ? date.start : moment().startOf('isoWeek').format('Y-MM-DD')}
											placeholder="dd/mm/aaaa"
											onChange={onStartDateChange}
											style={{
												fontSize: 12,
												border: 'none',
												textAlign: 'center',
												background: 'transparent',
												marginTop: 5,
											}}
										/>
										<span style={{ paddingLeft: 3, paddingRight: 3 }}>-</span>
										<Input
											type="date"
											name="end"
											defaultValue={date.end ? date.end : moment().endOf('isoWeek').format('Y-MM-DD')}
											placeholder="dd/mm/aaaa"
											onChange={onEndDateChange}
											style={{
												fontSize: 12,
												border: 'none',
												background: 'transparent',
												textAlign: 'center',
												marginTop: 10,
											}}
										/>
										{/* <DateRangeInput
											dateFormat="dd/MM/yyyy"
											locale="pt-br"
											onChange={handleDatesChange}
											value={range}
											modifiers={{
												disabled: { after: new Date() },
											}}
										/> */}
										<Button size="sm" color="primary" onClick={onSubmitDate}>
											Filtrar por Data
										</Button>
									</div>
								</FormGroup>
							</Form>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

function renderRows(page, heads, onClickHandler) {
	return page.map((item, index) => {
		return (
			<tr
				key={index}
				onClick={onClickHandler ? () => onClickHandler(item) : () => {}}
				style={{ cursor: onClickHandler ? 'pointer' : 'auto' }}
			>
				{Object.entries(item).map((entry, index) => {
					let Formatter;

					const item = heads.find(head => {
						return head.key === entry[0];
					});

					if (item && item.formatter) {
						Formatter = item.formatter;
					}

					if (item) {
						return (
							<td key={index}>{item.formatter ? <Formatter value={entry[1]}>{entry[1]}</Formatter> : entry[1]}</td>
						);
					}

					return null;
				})}
			</tr>
		);
	});
}

const AwesomeTable = props => {
	const onGenerateCsv = () => {
		const link = document.createElement('a');
		link.setAttribute('href', encodeURI(objectMatrixToCsv(props.rows)));
		link.setAttribute('download', 'Trips.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Filter items={props.rows}>
			{(filteredRows, updateSearch, updateType) => (
				<PaginationMaster limit={props.limit} omit={props.omitPagination} rows={filteredRows}>
					{page => (
						<Col md={12} lg={12}>
							{props.title && <h5 className="bold-text">{props.title}</h5>}

							{props.filter && (
								<FilterMenu
									generateCsv={props.generateCsv}
									onGenerateCsv={onGenerateCsv}
									updateSearch={updateSearch}
									updateType={updateType}
									types={props.heads}
									date={props.date}
									dates={props.dates}
									onSubmitDate={props.onSubmitDate}
									onStartDateChange={props.onStartDateChange}
									onEndDateChange={props.onEndDateChange}
								/>
							)}

							<Table responsive className="table--bordered">
								<thead>
									<tr>
										{props.heads.map(head => (
											<th key={head.key}>{head.name}</th>
										))}
									</tr>
								</thead>
								{!props.loading && <tbody>{renderRows(page, props.heads, props.onItemClick)}</tbody>}
							</Table>
							{props.loading && <Loader height={400} />}
							{!props.rows.length && !props.loading === 0 && <Error message={props.emptyMessage} />}
						</Col>
					)}
				</PaginationMaster>
			)}
		</Filter>
	);
};

AwesomeTable.propTypes = {
	heads: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			formatter: PropTypes.func,
		})
	).isRequired,
	rows: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AwesomeTable;
