import React from "react";
import { AuthContext } from "./Auth";

export const FinancialContext = React.createContext({});

class FinancialProvider extends React.PureComponent {
  static contextType = AuthContext;

  initialState = {
    bankSlips: [],
    bankSlipsAreLoading: true,
    bankSlipsHasErrored: false,
    balance: 0,
    balanceIsLoading: false,
    balanceHasErrored: false,
    withdrawal: [],
    withdrawalIsLoading: true,
    withdrawalHasErrored: false
  };

  state = this.initialState;

  _fetchBankslips = async () => {
    const {
      user: { company },
      request
    } = this.context;

    this.setState({ bankSlipsAreLoading: true, bankSlips: [] }, async () => {
      try {
        const response = await request(
          "GET",
          `api/company/v1/companies/${company}/financial/bankslip`
        );

        if (response.data.success) {
          this.setState(prev => ({
            ...prev,
            bankSlips: response.data.data.bankSlips,
            bankSlipsAreLoading: false,
            bankSlipsHasErrored: false
          }));
        } else throw new Error("Erro ao buscar os boletos");
      } catch (err) {
        this.setState(prev => ({
          ...prev,
          bankSlipsAreLoading: false,
          bankSlipsHasErrored: true
        }));
      }
    });
  };

  fetchBankslips = () => {
    const { isLoggedIn, addToQueue: addToAuthenticationQueue } = this.context;
    if (isLoggedIn) this._fetchBankslips();
    else addToAuthenticationQueue(this._fetchBankslips);
  };

  _fetchWithdrawal = () => {
    const {
      user: { company },
      request
    } = this.context;

    this.setState({ withdrawalIsLoading: true }, async () => {
      try {
        const response = await request(
          "GET",
          `api/company/v1/companies/${company}/financial`
        );

        if (response.data.success) {
          this.setState({
            withdrawal: response.data.data,
            withdrawalIsLoading: false,
            withdrawalHasErrored: false
          });
        }
      } catch (err) {
        this.setState({
          withdrawal: [],
          withdrawalIsLoading: false,
          withdrawalHasErrored: true
        });
      }
    });
  };

  fetchWithdrawal = () => {
    const { isLoggedIn, addToQueue: addToAuthenticationQueue } = this.context;
    if (isLoggedIn) this._fetchWithdrawal();
    else addToAuthenticationQueue(this._fetchWithdrawal);
  };

  _createBankSlip = async amount => {
    const {
      user: { company },
      request
    } = this.context;

    try {
      const response = await request(
        "POST",
        `api/company/v1/companies/${company}/financial/bankslip`,
        {
          amount
        }
      );

      if (response.data.success) {
        this._fetchBankslips();
      }
    } catch (err) {
      console.log(err);
    }
  };

  createBankSlip = (amount, cb) => {
    const { isLoggedIn, addToQueue: addToAuthenticationQueue } = this.context;
    if (isLoggedIn) this._createBankSlip(amount);
    else addToAuthenticationQueue(this._createBankSlip.bind(null, amount));
    if (cb) cb();
  };

  _fetchBalance = () => {
    const {
      user: { company },
      request
    } = this.context;

    this.setState({ balanceIsLoading: true }, async () => {
      try {
        const response = await request(
          "GET",
          `api/company/v1/companies/${company}/financial/balance`
        );
        if (response.data.success) {
          this.setState({
            balance: response.data.data.balance,
            balanceIsLoading: false,
            balanceHasErrored: false
          });
        }
      } catch (err) {
        this.setState({
          balance: 0,
          balanceIsLoading: false,
          balanceHasErrored: true
        });
      }
    });
  };

  fetchBalance = () => {
    const { isLoggedIn, addToQueue: addToAuthenticationQueue } = this.context;
    if (isLoggedIn) this._fetchBalance();
    else addToAuthenticationQueue(this._fetchBalance);
  };

  render() {
    const value = {
      ...this.state,
      fetchBankslips: this.fetchBankslips,
      createBankSlip: this.createBankSlip,
      fetchBalance: this.fetchBalance,
      fetchWithdrawal: this.fetchWithdrawal
    };

    return (
      <FinancialContext.Provider value={value}>
        {this.props.children}
      </FinancialContext.Provider>
    );
  }
}

export default FinancialProvider;
