import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
// import CostCenterDetails from "./components/CostCenterDetails";
import { Switch, Route, Link } from 'react-router-dom';
// import ParticipantForm from "./components/ParticipantForm";
import CostCenterList from './components/CostCenterList';
import Register from './components/RegisterIndex';
import SearchParticipant from './components/SearchParticipant';
// import ChangePaymentAccount from "../../../shared/components/ChangePaymentAccount";
// import LinkParticipants from './components/LinkParticipants';

import './styles.sass';

const CostCenters = () => {
	return (
		<Container>
			<Row>
				<Col md={12}>
					<div className="page-title-container">
						<h3 className="page-title float-left">Lojas</h3>
						<div>
							{/* <ChangePaymentAccount /> */}
							<Link color="primary" className="float-right" to="/cost_centers/register">
								<Button color="primary" size="sm" style={{ marginLeft: '10px' }}>
									Adicionar novo
								</Button>
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Switch>
				<Route path="/cost_centers/search_participant" render={props => <SearchParticipant {...props} />} />
				<Route path="/cost_centers/register" render={props => <Register {...props} />} />
				<Route exact path="/cost_centers" render={props => <CostCenterList {...props} />} />
			</Switch>
		</Container>
	);
};

export default CostCenters;
