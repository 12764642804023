import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../context/Auth';

import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Input } from 'reactstrap';

const LoginForm = () => {
	const { login } = useContext(AuthContext);

	const [state, setState] = useState({
		showPassword: false,
		email: '',
		password: '',
	});

	const handleChange = e => {
		const { name, value } = e.target;
		setState(prev => ({ ...prev, [name]: value }));
	};

	const handlePasswordVisibility = e => {
		e.preventDefault();
		setState(prev => ({ ...prev, showPassword: !prev.showPassword }));
	};

	const onSubmit = () => {
		login(state.email, state.password);
	};

	return (
		<div className="form">
			<div className="form__form-group">
				<span className="form__form-group-label">Email</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<AccountOutlineIcon />
					</div>
					<Input
						type="email"
						name="email"
						value={state.email}
						placeholder="email@exemplo.com"
						onChange={handleChange}
					/>
				</div>
			</div>
			<div className="form__form-group">
				<span className="form__form-group-label">Senha</span>
				<div className="form__form-group-field">
					<div className="form__form-group-icon">
						<KeyVariantIcon />
					</div>
					<Input
						type={state.showPassword ? 'text' : 'password'}
						value={state.password}
						name="password"
						placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
						onChange={handleChange}
					/>
					<button
						type="button"
						className={`form__form-group-button${state.showPassword ? ' active' : ''}`}
						onClick={e => handlePasswordVisibility(e)}
					>
						<EyeIcon />
					</button>
				</div>
				<div className="account__forgot-password" />
			</div>
			<button className="btn btn-primary account__btn" onClick={onSubmit}>
				Entrar
			</button>
		</div>
	);
};

export default LoginForm;
