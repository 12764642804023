import React from 'react';
import LogInForm from './components/LogInForm';
import { Link } from 'react-router-dom';

const LogIn = () => (
	<div className="account">
		<div className="account__wrapper">
			<div className="account__card">
				<div className="account__head">
					<h3 className="account__title">
						Bem vindo ao
						<span className="account__logo">
							{' '}
							<span className="account__logo-accent">Vaay</span>
						</span>
					</h3>
				</div>
				<LogInForm />
				<div className="account__have-account">
					<p>
						Ainda não é cliente? <Link to="/register">Cadastre sua empresa</Link>
					</p>
				</div>
			</div>
		</div>
	</div>
);

export default LogIn;
