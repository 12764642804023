import React, { Component } from 'react';
import ScrollToTop from './ScrollToTop';
import Router from './Router';

import { config as i18nextConfig } from '../../translations';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { hot } from 'react-hot-loader';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';

i18next.init(i18nextConfig);

class App extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			loaded: false,
		};
	}

	componentDidMount() {
		window.addEventListener('load', () => {
			this.setState({ loading: false });
			setTimeout(() => this.setState({ loaded: true }), 500);
		});
	}

	render() {
		const { loaded, loading } = this.state;

		return (
			<BrowserRouter basename="/">
				<I18nextProvider i18n={i18next}>
					<ScrollToTop>
						{!loaded ? (
							<div className={`load${loading ? '' : ' loaded'}`}>
								<div className="load__icon-wrap">
									<svg className="load__icon">
										<path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
									</svg>
								</div>
							</div>
						) : (
							<div>
								<Router />
							</div>
						)}
					</ScrollToTop>
				</I18nextProvider>
			</BrowserRouter>
		);
	}
}

export default hot(module)(App);
