import React from 'react';
import { Badge } from 'reactstrap';

export const getTripStatusColors = id => {
	switch (id) {
		case 'Corrida criada':
			return 'warning';
		case 'Corrida aceita pelo entregador':
			return 'primary';
		case 'Corrida iniciada pelo entregador':
			return 'primary';
		case 'Entregador chegou':
			return 'primary';
		case 'Corrida iniciada':
			return 'primary';
		case 'Voltando à origem':
			return 'primary';
		case 'Finalizada':
			return 'success';
		case 'Cancelada - Entregador':
			return 'danger';
		case 'Cancelada - Lojista':
			return 'dark';
		default:
			return 'primary';
	}
};

const StatusFormatter = ({ value }) => {
	const color = getTripStatusColors(value);

	return <Badge color={color}>{value}</Badge>;
};

export default StatusFormatter;
