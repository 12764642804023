import {
  FETCH_RULES_IS_LOADING,
  FETCH_RULES_HAS_ERRORED,
  FETCH_RULES_SUCCESS,
} from '../actionTypes';

const initialState = {
  rules: [],
  isLoading: false,
  hasErrored: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RULES_IS_LOADING:
      return {
        ...state,
        isLoading: action.bool,
        hasErrored: false,
        rules: [],
      };
    case FETCH_RULES_HAS_ERRORED:
      return {
        ...state,
        isLoading: false,
        hasErrored: action.bool,
        rules: [],
      };
    case FETCH_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        rules: action.rules,
      };
    default:
      return state;
  }
};
