import React, {
  useState,
  useContext,
  useToggle,
  useEffect,
  PureComponent,
} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../context/Auth";
import {
  CompanyIcon,
  FileDocumentBoxIcon,
  AddressMarkerIcon,
  FaceProfileIcon,
  PhoneIcon,
  PenIcon,
  WhatsappIcon,
  LocationCityIcon,
  CheckIcon,
} from "mdi-react";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import MailRuIcon from "mdi-react/MailRuIcon";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import { useCallback } from "react";

const origins = ["Ceofood", "Shopping Bosque Grão Pará", "Outros"];

const initialState = {
  full_name: "",
  name: "",
  document: "",
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  password: "",
  city_id: "",
  charge_first_name: "",
  charge_last_name: "",
  charge_phone: "",
  same_as_admin: false,
  store_address: "",
  store_whatsapp: "",
  location: null,
};

const RegisterForm = () => {
  const { register, cities } = useContext(AuthContext);

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? !prev[name] : value,
    }));
  };

  const updateState = useCallback((data) => {
    setState((prev) => ({ ...prev, ...data }));
  }, []);

  const onSubmit = () => {
    setErrors([]);
    if (state.store_address === "") {
      setErrors((errors) => [...errors, "Endereço é obrigatório"]);
    }

    if (state.location === null) {
      setErrors((errors) => [...errors, "Localização é obrigatória"]);
    }

    if (state.city_id === "") {
      setErrors((errors) => [...errors, "Cidade é obrigatória"]);
    }

    if (state.document === "") {
      setErrors((errors) => [...errors, "CNPJ é obrigatório"]);
    }

    if (state.full_name === "") {
      setErrors((errors) => [...errors, "Razão social é obrigatória"]);
    }

    if (state.name === "") {
      setErrors((errors) => [...errors, "Nome fantasia é obrigatório"]);
    }

    if (state.first_name === "") {
      setErrors((errors) => [...errors, "Primeiro nome é obrigatório"]);
    }

    if (state.last_name === "") {
      setErrors((errors) => [...errors, "Sobrenome é obrigatório"]);
    }

    if (state.phone === "") {
      setErrors((errors) => [...errors, "Telefone é obrigatório"]);
    }

    if (state.email === "") {
      setErrors((errors) => [...errors, "E-mail é obrigatório"]);
    }

    if (state.password === "") {
      setErrors((errors) => [...errors, "Senha é obrigatória"]);
    }

    if (state.store_name === "") {
      setErrors((errors) => [...errors, "Nome da loja é obrigatório"]);
    }

    if (state.store_whatsapp === "") {
      setErrors((errors) => [...errors, "WhatsApp é obrigatório"]);
    }

    setLoading(true);
    let error = false;

    if (state.same_as_admin) {
      state.charge_first_name = state.first_name;
      state.charge_last_name = state.last_name;
      state.charge_phone = state.phone;
      state.charge_email = state.email;
    }

    for (const value of Object.values(state)) {
      console.log(value, state[value]);
      if (value === "") {
        error = true;
      }
    }

    if (error) {
      toast.error("Todos os campos são obrigatórios");
      setLoading(false);
    } else {
      register(state).then((response) => {
        if (response) {
          clearState();
        } else {
          toast.error("Erro ao tentar cadastrar");
          setLoading(false);
        }
      });
    }
  };

  const clearState = () => {
    setState(() => initialState);
    setConfirmation(true);
  };

  return (
    <div className="account">
      <div
        className="account__wrapper container row"
        style={{ maxWidth: 1200 }}
      >
        <div
          className="account__card col-md-7"
          style={{
            backgroundImage: `url('img/bg-register.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: 300,
          }}
        />
        <div className="account__card col-md-5" style={{ minHeight: 600 }}>
          {confirmation ? (
            <div>
              <div className="account__head">
                <h3 className="account__title">
                  Cadastro realizado com sucesso
                </h3>
              </div>

              <div>
                <h4>
                  Sua conta está sendo avaliada por nossa equipe, aguarde que
                  entraremos contato por email
                </h4>
              </div>
            </div>
          ) : (
            <div>
              <div className="account__head">
                <h3 className="account__title">
                  Cadastre sua empresa
                  <span className="account__logo">
                    {" "}
                    <span className="account__logo-accent">Vaay</span>
                  </span>
                </h3>
              </div>
              <div
                className="form"
                style={{ display: confirmation ? "none" : "initial" }}
              >
                <h4
                  className="account__title"
                  style={{ marginTop: 20, marginBottom: 10 }}
                >
                  Informações da empresa
                </h4>
                <div className="form__form-group" style={{ marginBottom: -7 }}>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <CompanyIcon />
                    </div>
                    <Input
                      name="full_name"
                      placeholder="Razão social"
                      value={state.full_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <CompanyIcon />
                    </div>
                    <Input
                      name="name"
                      placeholder="Nome Fantasia"
                      value={state.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <FileDocumentBoxIcon />
                    </div>
                    <Input
                      name="document"
                      placeholder="CNPJ"
                      value={state.document}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -7 }}
                  >
                    <div className="form__form-group-icon">
                      <AddressMarkerIcon />
                    </div>
                    <Input
                      value={state.city_id}
                      onChange={handleChange}
                      name="city_id"
                      placeholder="Selecione a cidade"
                      type="select"
                      style={{
                        height: 32,
                        borderRadius: 0,
                        borderColor: "#EEE",
                        fontSize: 13,
                      }}
                    >
                      <option value="">Selecione a cidade:</option>
                      {cities.map((c) => {
                        return (
                          <option key={c._id} value={c._id}>
                            {c.full_cityname}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                </div>
                <h4
                  className="account__title"
                  style={{ marginTop: 40, marginBottom: 10 }}
                >
                  Gestor da plataforma
                </h4>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <FaceProfileIcon />
                    </div>
                    <Input
                      name="first_name"
                      placeholder="Primeiro Nome"
                      value={state.first_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <FaceProfileIcon />
                    </div>
                    <Input
                      name="last_name"
                      placeholder="Sobrenome"
                      value={state.last_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <PhoneIcon />
                    </div>
                    <Input
                      name="phone"
                      type="phone"
                      placeholder="Telefone"
                      value={state.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <MailRuIcon />
                    </div>
                    <Input
                      name="email"
                      type="email"
                      placeholder="E-mail corporativo"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <KeyVariantIcon />
                    </div>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Senha"
                      value={state.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <h4
                  className="account__title"
                  style={{ marginTop: 40, marginBottom: 10 }}
                >
                  Informações de Cobrança
                </h4>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <FaceProfileIcon />
                    </div>
                    <Input
                      name="charge_first_name"
                      placeholder="Primeiro Nome"
                      disabled={state.same_as_admin}
                      value={state.charge_first_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <FaceProfileIcon />
                    </div>
                    <Input
                      name="charge_last_name"
                      placeholder="Sobrenome"
                      disabled={state.same_as_admin}
                      value={state.charge_last_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <PhoneIcon />
                    </div>
                    <Input
                      name="charge_phone"
                      type="phone"
                      placeholder="Telefone"
                      disabled={state.same_as_admin}
                      value={state.charge_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <MailRuIcon />
                    </div>
                    <Input
                      name="email"
                      type="email"
                      placeholder="E-mail corporativo"
                      value={state.charge_email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginTop: "30px" }}
                  >
                    <label
                      className={`checkbox-btn`}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        className="checkbox-btn__checkbox"
                        type="checkbox"
                        name="same_as_admin"
                        value={state.same_as_admin}
                        onChange={handleChange}
                      />
                      <span className="checkbox-btn__checkbox-custom">
                        <CheckIcon />
                      </span>
                      <span className="checkbox-btn__label">
                        Os dados de cobrança são os mesmos que o do gestor da
                        plataforma
                      </span>
                    </label>
                  </div>
                </div>
                <h4
                  className="account__title"
                  style={{ marginTop: 40, marginBottom: 10 }}
                >
                  Dados da loja
                </h4>
                <div className="form__form-group">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <PenIcon />
                    </div>
                    <Input
                      name="store_name"
                      placeholder="Nome da loja"
                      value={state.store_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <PlaceAutoComplete updateCallback={updateState} />

                {/* </div> */}
                <div className="form__form-group form__form-group--forgot">
                  <div
                    className="form__form-group-field"
                    style={{ marginBottom: -27 }}
                  >
                    <div className="form__form-group-icon">
                      <WhatsappIcon />
                    </div>
                    <Input
                      name="store_whatsapp"
                      placeholder="WhatsApp"
                      value={state.store_whatsapp}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {
                  errors.length > 0 &&
                  errors.map((e) => (
                    <div className="alert alert-danger" role="alert"
                      style={{ display: 'block', marginBottom: 4 }}
                    >
                      <p key={e}>{e}</p>
                    </div>
                  ))
                }
                <div className="account__btns"
                  style={{  marginTop: 20 }}
                >
                  <button
                    className="btn btn-primary account__btn"
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    Cadastrar
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="account__have-account">
            <p>
              {!confirmation && "Já é cliente?"}{" "}
              <Link to="/log_in">Acesse</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const __googleMaps = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY
  }&libraries=places&callback=init`;

class PlaceAutoComplete extends PureComponent {
  input = React.createRef();
  autoComplete = null;

  constructor(props) {
    super(props);

    window.init = this.startAutocompleteService;
  }

  componentDidMount() {
    if (window.google) {
      this.startAutocompleteService();
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = __googleMaps;
    document.body.appendChild(script);
  }

  startAutocompleteService = () => {
    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.input.current
    );
    this.autocomplete.setFields(["address_components", "geometry", "name"]);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        window.alert("Selecione um local");
        return;
      }

      let store_address = this.input.current.value || place.name;

      const { lat, lng } = place.geometry.location.toJSON();

      this.props.updateCallback({ store_address, location: [lat, lng] });
    });
  };

  render() {
    return (
      <div className="form__form-group">
        <div className="form__form-group-field" style={{ marginBottom: -27 }}>
          <div className="form__form-group-icon">
            <AddressMarkerIcon />
          </div>
          <input
            type="text"
            placeholder="Endereço"
            className="form-control"
            ref={this.input}
          />
        </div>
      </div>
    );
  }
}

export default RegisterForm;
