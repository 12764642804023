import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";
import { Button } from 'reactstrap';
// import TopbarMail from './TopbarMail';
// import TopbarNotification from './TopbarNotification';
// import TopbarSearch from './TopbarSearch';
// import TopbarLanguage from './TopbarLanguage';
// import TopbarPaymentAccount from "./TopbarPaymentAccount";

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility
    } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/dashboard_default" />
          </div>
          <div className="topbar__right">
            {/* <Link className="topbar__logo" to="/dashboard_default" /> */}
            {/* <TopbarSearch /> */}
            {/* <TopbarNotification /> */}
            {/* <TopbarMail new /> */}
            {/* <TopbarPaymentAccount /> */}
            <a href="https://delivery.vaay.app" target="_blank" >
              <Button style={{
                margin: '10px',
                borderRadius: '100px',
                backgroundColor: '#272b32',
                border: 'none',
                color: '#4ce1b6',
                fontWeight: 'bold'
              }}>Solicitar Entregas</Button>
            </a>
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
