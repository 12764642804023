import React, { useEffect, useContext } from 'react';
import { TripsContext } from '../../../context/Trips';
import { AuthContext } from '../../../context/Auth';
import InvoicesTable from './components/InvoicesTable';

import { Container, Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import moment from 'moment';

const Trips = props => {
	const { user } = useContext(AuthContext);
	const { trips, tripsAreLoading, tripsHasErrored, fetchTrips } = useContext(TripsContext);

	useEffect(() => {
		if (!!user) fetchTrips("", {
			start: moment().subtract(1,'w').startOf('isoWeek').format('Y-MM-DD'),
			end: moment().format('Y-MM-DD')
		});
	}, [user]);

	return (
		<Container>
			<Row>
				<Col md={12}>
					<h3 className="page-title">Faturas</h3>
				</Col>
			</Row>

			<InvoicesTable
				trips={trips}
				tripsAreLoading={tripsAreLoading}
				tripsHasErrored={tripsHasErrored}
				fetchCompanyTrips={fetchTrips}
			/>
		</Container>
	);
};

export default translate('common')(Trips);
