import { CardBody } from 'reactstrap';
import React, { Fragment } from 'react';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Formik } from 'formik';
import { Redirect, Link } from 'react-router-dom';
import LinkParticipants from './LinkParticipants';
import { BillingContext } from '../../../../context/Billing';

class SearchParticipant extends React.Component {
	static contextType = BillingContext;

	render() {
		if (!this.context.selectedCostCenter) {
			return <Redirect to="/cost_centers" />;
		}

		return (
			<Fragment>
				<div
					style={{
						maxWidth: 600,
						display: 'block',
						margin: '0 auto',
					}}
				>
					<CardBody>
						<Formik
							onSubmit={values => {
								this.context.queryParticipants(values.query);
							}}
							render={props => {
								return (
									<form className="form" onSubmit={props.handleSubmit}>
										<div className="form__form-group">
											<span className="form__form-group-label">Buscar Usuário</span>
											<div className="form__form-group-field">
												<div className="form__form-group-icon">
													<AccountOutlineIcon />
												</div>
												<input
													name="query"
													component="input"
													type="text"
													onChange={props.handleChange}
													value={props.values.query}
													placeholder="Email ou Telefone"
												/>
											</div>
										</div>
										<div className="account__btn">
											<button className="btn btn-primary account__btn" type="submit">
												Buscar Usuário
											</button>
										</div>
									</form>
								);
							}}
						/>
					</CardBody>
					<Link to={'/cost_centers/details'}>Voltar para Centro de Custo</Link>
				</div>
				<LinkParticipants />
			</Fragment>
		);
	}
}

export default SearchParticipant;
