import {
  SET_PARTICIPANT,
  LOAD_PARTICIPANTS,
  FETCH_ALL_PARTICIPANTS,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANTS,
} from '../actionTypes';
// import { STATUS_CODES } from "http";

const DEFAULT_PARTICIPANTS = {
  supposedParticipants: [],
  participants: [],
  participant: {},
  hasErrored: false,
  error: {},
};

export default (state = DEFAULT_PARTICIPANTS, action) => {
  switch (action.type) {
    case SET_PARTICIPANT:
      return {
        ...state,
        participant: action.participant,
        addParticipantSuccess: false,
      };
    case LOAD_PARTICIPANTS:
      return {
        ...state,
        supposedParticipants: action.participants,
        addParticipantSuccess: false,
      };
    case FETCH_ALL_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
        addParticipantSuccess: false,
      };
    case REMOVE_PARTICIPANTS:
      return DEFAULT_PARTICIPANTS;
    case ADD_PARTICIPANT:
      // console.log(
      //   state.supposedParticipants.filter(
      //     part => part._id !== action.participant._id
      //   )
      // );
      return {
        ...state,
        supposedParticipants: state.supposedParticipants.filter(
          part => part._id !== action.participant._id
        ),
        addParticipantSuccess: true,
      };
    default:
      return state;
  }
};
