import {
	FETCH_DASHBOARD_PARTICIPANTS,
	FETCH_DASHBOARD_PARTICIPANTS_HAS_ERRORED,
	FETCH_DASHBOARD_PARTICIPANTS_IS_LOADING,
	FETCH_DASHBOARD_TRIPS,
	FETCH_DASHBOARD_TRIPS_HAS_ERRORED,
	FETCH_DASHBOARD_TRIPS_IS_LOADING,
	FETCH_DASHBOARD_EXPENSES,
	FETCH_DASHBOARD_EXPENSES_HAS_ERRORED,
	FETCH_DASHBOARD_EXPENSES_IS_LOADING,
	FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES,
	FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_HAS_ERRORED,
	FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_IS_LOADING,
	FETCH_DASHBOARD_COST_CENTER_COSUMPTION,
	FETCH_DASHBOARD_COST_CENTER_COSUMPTION_HAS_ERRORED,
	FETCH_DASHBOARD_COST_CENTER_COSUMPTION_IS_LOADING,
	FETCH_DASHBOARD_TOP_PROVIDERS,
	FETCH_DASHBOARD_TOP_PROVIDERS_IS_LOADING,
	FETCH_DASHBOARD_TOP_PROVIDERS_HAS_ERRORED,
} from '../actionTypes';

const initialState = {
	participants: {
		list: [],
		isLoading: false,
		hasErrored: false,
		total: 0,
	},
	trips: {
		list: [],
		isLoading: false,
		hasErrored: false,
	},
	expenses: {
		list: [],
		isLoading: false,
		hasErrored: false,
		total: 0,
	},
	paymentAccountExpenses: {
		list: [],
		isLoading: false,
		hasErrored: false,
	},
	costCenterCosumption: {
		list: [],
		isLoading: false,
		hasErrored: false,
	},
	topProviders: {
		list: [],
		isLoading: false,
		hasErrored: false,
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_DASHBOARD_PARTICIPANTS:
			return {
				...state,
				participants: {
					...state.participants,
					list: action.participants.participants,
					total: action.participants.total,
					isLoading: false,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_PARTICIPANTS_HAS_ERRORED:
			return {
				...state,
				participants: {
					...state.participants,
					list: [],
					isLoading: false,
					hasErrored: true,
				},
			};
		case FETCH_DASHBOARD_PARTICIPANTS_IS_LOADING:
			return {
				...state,
				participants: {
					...state.participants,
					list: [],
					isLoading: true,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_TRIPS:
			return {
				...state,
				trips: {
					...state.trips,
					list: action.trips.datasets,
					labels: action.trips.labels,
					isLoading: false,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_TRIPS_HAS_ERRORED:
			return {
				...state,
				trips: {
					...state.trips,
					list: [],
					isLoading: false,
					hasErrored: true,
				},
			};
		case FETCH_DASHBOARD_TRIPS_IS_LOADING:
			return {
				...state,
				trips: {
					...state.trips,
					list: [],
					isLoading: true,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_EXPENSES:
			return {
				...state,
				expenses: {
					...state.expenses,
					list: action.expenses.expenses,
					total: action.expenses.total,
					isLoading: false,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_EXPENSES_HAS_ERRORED:
			return {
				...state,
				expenses: {
					...state.expenses,
					list: [],
					isLoading: false,
					hasErrored: true,
				},
			};
		case FETCH_DASHBOARD_EXPENSES_IS_LOADING:
			return {
				...state,
				expenses: {
					...state.expenses,
					list: [],
					isLoading: true,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES:
			return {
				...state,
				paymentAccountExpenses: {
					...state.paymentAccountExpenses,
					list: action.paymentAccountExpenses,
					isLoading: false,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_HAS_ERRORED:
			return {
				...state,
				paymentAccountExpenses: {
					...state.paymentAccountExpenses,
					list: [],
					isLoading: false,
					hasErrored: true,
				},
			};
		case FETCH_DASHBOARD_PAYMENT_ACCOUNT_EXPENSES_IS_LOADING:
			return {
				...state,
				paymentAccountExpenses: {
					...state.paymentAccountExpenses,
					list: [],
					isLoading: true,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_COST_CENTER_COSUMPTION:
			return {
				...state,
				costCenterCosumption: {
					...state.costCenterCosumption,
					list: action.costCenterCosumption,
					maxValue: action.maxValue,
					isLoading: false,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_COST_CENTER_COSUMPTION_HAS_ERRORED:
			return {
				...state,
				costCenterCosumption: {
					...state.costCenterCosumption,
					list: [],
					isLoading: false,
					hasErrored: true,
				},
			};
		case FETCH_DASHBOARD_COST_CENTER_COSUMPTION_IS_LOADING:
			return {
				...state,
				costCenterCosumption: {
					...state.costCenterCosumption,
					list: [],
					isLoading: true,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_TOP_PROVIDERS:
			return {
				...state,
				topProviders: {
					...state.topProviders,
					list: action.topProviders,
					isLoading: false,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_TOP_PROVIDERS_IS_LOADING:
			return {
				...state,
				topProviders: {
					...state.topProviders,
					list: [],
					isLoading: true,
					hasErrored: false,
				},
			};
		case FETCH_DASHBOARD_TOP_PROVIDERS_HAS_ERRORED:
			return {
				...state,
				topProviders: {
					...state.topProviders,
					list: [],
					isLoading: false,
					hasErrored: true,
				},
			};
		default:
			return state;
	}
};
