import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import AwesomeTable from '../../../../shared/components/table/AwesomeTable';

import { Card, CardBody, Col } from 'reactstrap';
import { translate } from 'react-i18next';

import moment from 'moment';

const InvoicesTable = ({ t, trips, tripsAreLoading, fetchCompanyTrips }) => {
	const [invoices, setInvoices] = useState([]);
	const [date, setDate] = useState({
		start: moment().subtract(1,'w').startOf('isoWeek').format('Y-MM-DD'),
		end: moment().endOf('isoWeek').format('Y-MM-DD')
	});

	useEffect(() => {
		setInvoices(makeInvoices(trips));
	}, [trips]);

	function fetchCompanyTripsByDate(e) {
		e.preventDefault();
		fetchCompanyTrips('', date);
	}

	function updateStartDate(e) {
		setDate({ ...date, start: e.target.value });
	}

	function updateEndDate(e) {
		setDate({ ...date, end: e.target.value });
	}

	const toInvoice = useCallback(item => {
		return document.location = `/invoce/${moment(item.date_start).format("Y-MM-DD")}/${moment(item.date_end).format("Y-MM-DD")}`;
	}, []);
	
	function makeInvoices(trips){
		let weeks = [];
		let date_start = moment(date.start).startOf('isoWeek').toDate();
		let date_end = moment(date.start).endOf('isoWeek').toDate();
		let status = moment(date_end).diff(moment()) >= 0 ? 'Fatura Aberta' : 'Fatura Fechada';
		let due_date = moment(date_end).add(2,'d').format('DD/MM/Y');

		if(trips.length){
			weeks.push({
				display_text: `${moment(date_start).format('DD/MM/Y')} - ${moment(date_end).format('DD/MM/Y')}`,
				date_start,
				date_end,
				total_deliveries: 0,
				total_value: 0,
				status,
				due_date
			});

			let current_week = weeks[weeks.length - 1];
			let current_deliveries = trips.filter( t => t.is_trip_completed == 1 && moment(t.created_at).isBetween(current_week.date_start, current_week.date_end));
			while (current_deliveries.length){
				current_week.deliveries = current_deliveries;
				current_week.total_deliveries = current_deliveries.reduce((total, r) => total + r.destinations.length, 0);
				current_week.total_value = current_deliveries.reduce((total, r) => total + r.total_for_company, 0);
				current_deliveries.map(t => console.log(current_week.display_text, t.is_trip_completed, moment(t.created_at).toLocaleString()));

				let date_start = moment(current_week.date_start).add(1, 'w');
				let date_end = moment(current_week.date_end).add(1, 'w');
				let status = moment(date_end).diff(moment()) >= 0 ? 'Fatura Aberta' : 'Fatura Fechada';
				let due_date = moment(date_end).add(2,'d').format('DD/MM/Y');

				weeks.push({
					display_text: `${moment(date_start).format('DD/MM/Y')} - ${moment(date_end).format('DD/MM/Y')}`,
					date_start,
					date_end,
					total_deliveries: 0,
					total_value: 0,
					status,
					due_date
				});

				current_week = weeks[weeks.length - 1];
				current_deliveries = trips.filter(t => t.is_trip_completed == 1 && moment(t.created_at).isBetween(current_week.date_start, current_week.date_end));
			}
		}
		return weeks.filter((v,i, arr) => i < arr.length-1);
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<AwesomeTable
						filter
						heads={[
							{key:'display_text', name:'Período da fatura'},
							{key:'total_deliveries', name:'Quant. Entregas'},
							{key:'total_value', name:'R$ Total', formatter: ({value}) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(value))},
							{key:'status', name:'Status'},
							{key:'due_date', name:'Vencimento'},
						]}
						rows={invoices}
						date
						dates={date}
						onStartDateChange={updateStartDate}
						onEndDateChange={updateEndDate}
						onSubmitDate={fetchCompanyTripsByDate}
						loading={tripsAreLoading}
						limit={15}
						onItemClick={toInvoice}
						emptyMessage="Não existem faturas no período"
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

InvoicesTable.propTypes = {
	t: PropTypes.func.isRequired,
	trips: PropTypes.array.isRequired,
};

export default translate('common')(InvoicesTable);
