import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, Badge } from 'reactstrap';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const ModalDestinations = props => {
	const { isOpen, item, toggle } = props;
	const tripData = item.data || {};
	const trip = item || {};

	const LineLeft = styled.h5`
		position: relative;
		display: flex;
		float: left;
		font-size: 18px;
		line-height: 1.5em;
		text-align: left;
		padding: 0 5px;
	`;
	const LineRight = styled.h5`
		position: relative;
		display: flex;
		float: right;
		font-size: 18px;
		line-height: 1.5em;
		text-align: left;
		padding: 0 5px;
	`;
	const Line = styled.h5`
		width: 100%;
		position: relative;
		display: flex;
		float: left;
		font-size: 18px;
		line-height: 1.5em;
		text-align: left;
		padding: 0 5px;
	`;
	const LineBadge = styled.span`
		position: relative;
		display: flex;
		float: left;
		font-size: 16px;
		line-height: 1.2;
		text-align: left;
	`;
	const TitleSpace = styled.h4`
		font-weight: bold;
		line-height: 0.9;
		text-transform: uppercase;
		font-size: small;
		margin: 0 -15px;
		padding: 8px 22px;
		margin-bottom: 10px;
		background: #EEE;
		color: #222;
		text-align: left;
		border-top: solid 30px #FFF;
		display: flex;
	`;
	const Title = styled.h4`
		font-weight: bold;
		line-height: 0.9;
		text-transform: uppercase;
		font-size: small;
		margin: 0 -15px;
		padding: 8px 22px;
		margin-bottom: 10px;
		background: #EEE;
		color: #222;
		text-align: left;
	`;
	const Hr = styled.hr`
		float: left;
		width: 100%;
	`;
	const DestineContainer = styled.div`
		margin-bottom: 12px;
	`;

	// const DisplayProviderStatus = ({ trip }) => {
	// 	switch (trip.is_provider_status) {
	// 		case 2:
	// 			return <Line className="badge-warning">Prestador a caminho</Line>;
	// 		case 4:
	// 			return <Line className="badge-warning">Prestador Chegou</Line>;
	// 		case 6:
	// 			return <Line className="badge-primary">Iniciado</Line>;
	// 		case 9:
	// 			return <Line className="badge-success">Finalizado</Line>;
	// 		default:
	// 			return <Line className="badge-default">Aguardando Atendimento</Line>;
	// 	}
	// };
	// const TripStatus = ({ trip }) => {
	// 	if (trip.is_trip_cancelled) {
	// 		return <Line className="badge-danger">Cancelado</Line>;
	// 	} else if (trip.is_trip_completed) {
	// 		return <Line className="badge-success">Finalizado</Line>;
	// 	} else {
	// 		return <DisplayProviderStatus trip={trip} />;
	// 	}
	// };

	const getTripStatusColors = id => {
		switch (id) {
			case 'Corrida criada':
				return 'badge badge-warning';
			case 'Corrida aceita pelo entregador':
				return 'badge badge-primary';
			case 'Corrida iniciada pelo entregador':
				return 'badge badge-primary';
			case 'Entregador chegou':
				return 'badge badge-primary';
			case 'Corrida iniciada':
				return 'badge badge-primary';
			case 'Voltando à origem':
				return 'badge badge-primary';
			case 'Finalizada':
				return 'badge badge-success';
			case 'Cancelada - Entregador':
				return 'badge badge-danger';
			case 'Cancelada - Lojista':
				return 'badge badge-dark';
			default:
				return 'badge badge-primary';
		}
	};

	return (
		<Fragment>
			<Modal isOpen={isOpen} toggle={toggle} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<ModalHeader toggle={toggle}>
					{!!item ? <div><Line><strong>ID</strong> #{tripData.unique_id}&nbsp;<LineBadge className={getTripStatusColors(item.status)}>{item.status}</LineBadge></Line> </div> : null}
				</ModalHeader>
				<ModalBody>
					<Title><strong>Informações</strong></Title>
					<Line>
						<strong>Entregador:</strong>
						&nbsp;
						{trip.provider}
					</Line>
					<Line>
						<strong>Origem:</strong>
						&nbsp;
						{tripData.source_address}
					</Line>
					{!tripData.destinations || tripData.destinations.length === 0 ? (
						<Line>
							<strong>Destino </strong>
							&nbsp;
							{tripData.destination_address}
						</Line>
					) : null}
					<Line>
						<strong>Total:</strong>
						&nbsp;
						<NumberFormat
							value={tripData.is_trip_cancelled ? '-' : tripData.total_for_company}
							displayType={'text'}
							fixedDecimalScale={true}
							decimalScale={2}
							thousandSeparator={'.'}
							decimalSeparator={','}
						/>
					</Line>
					{/* <Hr /> */}
					<TitleSpace><strong>Data / Hora</strong></TitleSpace>
					<Line>
						<strong>Solicitado</strong>
						:&nbsp;
						{moment(tripData.created_at).format('DD/MM/Y HH:mm')}
					</Line>
					<Line>
						<strong>Início</strong>
						:&nbsp;
						{moment(tripData.provider_trip_start_time).format('DD/MM/Y HH:mm')}
					</Line>
					<Line>
						<strong>Fim</strong>
						:&nbsp;
						{moment(tripData.provider_trip_end_time).format('DD/MM/Y HH:mm')}
					</Line>

					<TitleSpace><strong>Entregas</strong></TitleSpace>
					{tripData.destinations &&
						tripData.destinations.map((dest, i) => (
							<DestineContainer>
								<LineBadge className={"badge badge-secondary"}><strong>Entrega {i + 1}</strong></LineBadge>
								<Line>
									<strong>Endereço:</strong>
									&nbsp;
									{dest.address}
								</Line>
								<Line>
									<strong>Distancia:</strong>
									&nbsp;
									{dest.distance}km
								</Line>
								{dest.user_name ? (
									<Line>
										<strong>Nome:</strong>
										&nbsp;
										{dest.user_name}
									</Line>
								) : null}
								{dest.user_phone ? (
									<Line>
										<strong>Telefone:</strong>
										&nbsp;
										{dest.user_phone}
									</Line>
								) : null}
								{dest.description ? (
									<Line>
										<strong>Descriçāo:</strong>
										&nbsp;
										{dest.description}
									</Line>
								) : null}
								<Hr/>
							</DestineContainer>
						))}
				</ModalBody>
			</Modal>
		</Fragment>
	);
};

export default ModalDestinations;
