import { useReducer, useEffect } from "react";

function usePagination(initialPage, elements, size = 15) {
  const initialState = {
    elements,
    currentElements: [],
    current: initialPage,
    total: Math.ceil(elements.length / size),
    size,
    initialPage
  };

  function reducer(state, action) {
    const getPageElements = (arr, size, current) => {
      const itemIndex = size * (current - 1);
      return arr.slice(itemIndex, itemIndex + size);
    };

    switch (action.type) {
      case "define_page_elements":
        return {
          ...state,
          currentElements: getPageElements(
            state.elements,
            state.size,
            state.current
          )
        };
      case "set_current_page":
        return {
          ...state,
          current: Number(action.payload)
        };
      case "update_elements":
        return {
          ...state,
          elements: Array.isArray(action.payload) ? action.payload : []
        };
      case "update_max_elements":
        return {
          ...state,
          size: Number(action.payload)
        };
      default:
        return initialState;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "set_current_page", payload: 1 });
    dispatch({ type: "define_page_elements" });
  }, [state.elements]);

  useEffect(() => {
    dispatch({ type: "define_page_elements" });
  }, [state.current]);

  return {
    updateCurrentPage(page) {
      dispatch({ type: "set_current_page", payload: page });
    },
    updateElements(newItems) {
      dispatch({ type: "update_elements", payload: newItems });
    },
    updateMaxElements(maxElements) {
      dispatch({ type: "update_max_elements", payload: maxElements });
    },
    currentElements: state.currentElements,
    current: state.current,
    total: Math.ceil(state.elements.length / state.size),
    initialPage: state.initialPage,
    size: state.size,
    elements: state.elements
  };
}

export default usePagination;
