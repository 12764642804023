import React from 'react';
import { AuthContext } from './Auth';

export const BillingContext = React.createContext({});

class BillingProvider extends React.PureComponent {
	queue = [];
	static contextType = AuthContext;

	initialState = {
		selectedPaymentAccount: null,
		selectedCostCenter: null,
		paymentAccounts: [],
		paymentAccountsAreLoading: true,
		paymentAccountDetailsAreLoading: false,
		paymentAccountDetailsHasErrored: false,
		costCenters: [],
		costCentersAreLoading: true,
		costCenterDetailsAreLoading: false,
		participants: [],
		supposedParticipants: [],
		participantsAreLoading: false,
		cities: []
	};

	state = this.initialState;

	_addToQueue = func => {
		this.queue.push(func);
	};

	_runQueue = () => {
		console.log('Running Billing Queue', this.queue.length);
		this.queue.forEach(func => func());
		setTimeout(() => (this.queue = []), 2000);
	};

	componentDidMount() {
	  	this.fetchCities();
	}

	fetchDefaultPaymentAccount = async () => {
		const cb = () => {
			const defaultPaymentAccount = this.state.paymentAccounts[0];

			if (defaultPaymentAccount) {
				this.fetchPaymentAccountDetails(defaultPaymentAccount._id);
			}
		};

		if (!this.state.paymentAccounts.length) {
			return this.fetchPaymentAccounts(cb);
		}

		return this.fetchPaymentAccountDetails(this.state.paymentAccounts[0]._id);
	};

	_fetchPaymentAccounts = (callback = () => {}) => {
		const {
			request,
			user: { company },
		} = this.context;

		this.setState(
			{
				paymentAccounts: [],
				paymentAccountsAreLoading: true,
			},
			async () => {
				console.log(new Date());

				try {
					const response = await request('GET', `api/company/v1/companies/${company}/payment-accounts/`);

					if (response.data.success) {
						this.setState(
							{
								paymentAccounts: response.data.data,
								paymentAccountsAreLoading: false,
								paymentAccountsHasErrored: false,
							},
							callback
						);
					}
				} catch (err) {
					this.setState(
						{
							paymentAccounts: [],
							paymentAccountsAreLoading: false,
							paymentAccountsHasErrored: true,
						},
						callback
					);
				}
			}
		);
	};

	fetchPaymentAccounts = async (callback = () => {}) => {
		const { isLoggedIn, addToQueue: addToAuthenticationQueue } = this.context;
		if (isLoggedIn) this._fetchPaymentAccounts(callback);
		else addToAuthenticationQueue(() => this._fetchPaymentAccounts(callback));
	};

	fetchPaymentAccountDetails = paymentAccountId => {
		const {
			request,
			user: { company },
		} = this.context;

		this.setState(
			{
				selectedPaymentAccount: null,
				paymentAccountDetailsAreLoading: true,
				costCenters: [],
				costCentersAreLoading: true,
			},
			async () => {
				try {
					const response = await request(
						'GET',
						`api/company/v1/companies/${company}/payment-accounts/${paymentAccountId}/`
					);

					if (response.data.success) {
						this.setState(
							{
								selectedPaymentAccount: response.data.data,
								paymentAccountDetailsAreLoading: false,
								paymentAccountDetailsHasErrored: false,
							},
							() => {
								this.fetchCostCenters('payment-account');
							}
						);
					}
				} catch (err) {
					this.setState({
						selectedPaymentAccount: null,
						paymentAccountDetailsAreLoading: false,
						paymentAccountDetailsHasErrored: true,
					});
				}
			}
		);
	};

	createPaymentAccount = async data => {
		const {
			user: { company },
			request,
		} = this.context;

		try {
			const response = await request('POST', `api/company/v1/companies/${company}/payment-accounts/`, data);

			if (response.data.success) {
				return 'Conta de pagamento adicionada com sucesso';
			}
		} catch (err) {
			return err;
		}
	};

	fetchCostCenters = query => {
		const {
			request,
			user: { company },
		} = this.context;

		// let paymentAccountId;

		// if (this.state.selectedPaymentAccount) {
		//   paymentAccountId = this.state.selectedPaymentAccount.payment_account._id;
		// }

		this.setState(
			{
				costCenters: [],
				costCentersAreLoading: true,
			},
			async () => {
				try {
					const response = await request('GET', `api/company/v1/companies/${company}/cost-centers`);

					// let costCenters = [];

					// switch (query) {
					//   case "company":
					//     response.data.data.reduce((acc, value) => {
					//       value["cost_centers"].forEach(cc => {
					//         acc.push(cc);
					//       });
					//       return acc;
					//     }, costCenters);
					//     break;
					//   default:
					//     costCenters = response.data.data[0].cost_centers;
					//     break;
					// }

					// console.log(response);
					this.setState({
						costCenters: (response && response.data && response.data.data) || [],
						costCentersAreLoading: false,
						costCentersHasErrored: false,
					});
				} catch (err) {
					this.setState({
						costCenters: [],
						costCentersAreLoading: false,
						costCentersHasErrored: true,
					});
				}
			}
		);
	};

	fetchCities = async () => {
		const { request } = this.context;
		try {
			const response = await request('GET', `api/company/v1/cities`);
			this.setState({
				cities: (response && response.data && response.data.data) || []
			});
		} catch (err) {
			this.setState({
				cities: []
			});
		}
	};

	removeCostCenter = async (costCenterId) => {
		const {
			user: { company },
			request,
		} = this.context;

		try {
			const response = await request('DELETE', `api/company/v1/companies/${company}/cost-centers/${costCenterId}`);
			this.fetchCostCenters();
		} catch (err) {
			console.log(err);
		}
	}
	fetchCostCenterDetails = async costCenterId => {
		const {
			user: { company },
			request,
		} = this.context;

		this.setState({
			selectedCostCenter: null,
			costCenterDetailsAreLoading: true,
		});

		// let paymentAccountId = this.state.selectedPaymentAccount.payment_account._id;

		// if (!paymentAccountId) {
		//   return;
		// }

		try {
			const response = await request('GET', `api/company/v1/companies/${company}/cost-centers/${costCenterId}`);

			if (response.data.success) {
				this.setState(
					{
						selectedCostCenter: response.data.data,
						costCenterDetailsAreLoading: false,
					},
					this._runQueue
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	createCostCenter = async data => {
		const {
			user: { company },
			request,
		} = this.context;

		try {
			const response = await request(
				'POST',
				`api/company/v1/companies/${company}/cost-centers/`,
				data
			);

			if (response.data.success) {
				return 'Centro de Custo adicionado com sucesso';
			}
		} catch (err) {
			return err;
		}
	};

	_fetchParticipants = async query => {
		const {
			user: { company },
			request,
		} = this.context;

		this.setState({
			participants: [],
			supposedParticipants: [],
			participantsAreLoading: true,
		});

		let response = null;

		const paymentAccountId = this.state.selectedPaymentAccount.payment_account._id;
		const costCenterId = this.state.selectedCostCenter.cost_center._id;

		switch (query) {
			case 'payment':
				response = await request('get', `api/company/v1/companies/${company}/participants/`);
				break;
			case 'cost':
				response = await request(
					'get',
					`api/company/v1/companies/${company}/payment-accounts/${paymentAccountId}/participants/`
				);
				break;
			default:
				response = await request(
					'get',
					`api/company/v1/companies/${company}/payment-accounts/${paymentAccountId}/cost-centers/${costCenterId}/participants/`
				);
				break;
		}

		if (response && response.data.success) {
			this.setState({
				participants: response.data.data.participants,
				participantsAreLoading: false,
			});
		}
	};

	fetchParticipants = query => {
		const { isLoggedIn, addToQueue: addToAuthenticationQueue } = this.context;

		if (!this.state.selectedCostCenter) {
			return this._addToQueue(() => this.fetchParticipants(query));
		}

		if (isLoggedIn) this._fetchParticipants(query);
		else addToAuthenticationQueue(() => this._fetchParticipants(query));
	};

	queryParticipants = async query => {
		const { request } = this.context;
		try {
			const response = await request('GET', `api/company/v1/users/${query}`);
			if (response.data.success) {
				this.setState({
					supposedParticipants: response.data.data,
				});

				return response.data.data;
			}
		} catch (err) {
			console.log(err);
		}
	};

	confirmParticipant = async participant => {
		const {
			user: { company },
			request,
		} = this.context;

		const paymentAcountId = this.state.selectedPaymentAccount.payment_account._id;
		const costCenterId = this.state.selectedCostCenter.cost_center._id;

		try {
			const response = await request(
				'POST',
				`api/company/v1/companies/${company}/payment-accounts/${paymentAcountId}/cost-centers/${costCenterId}/participants/${participant._id}/link`
			);

			if (response.data.success) {
				return 'Usuário adicionado com sucesso';
			}
		} catch (err) {
			return err;
		}
	};

	render() {
		const value = {
			...this.state,
			fetchPaymentAccounts: this.fetchPaymentAccounts,
			fetchPaymentAccountDetails: this.fetchPaymentAccountDetails,
			fetchDefaultPaymentAccount: this.fetchDefaultPaymentAccount,
			createPaymentAccount: this.createPaymentAccount,
			fetchCostCenterDetails: this.fetchCostCenterDetails,
			createCostCenter: this.createCostCenter,
			fetchParticipants: this.fetchParticipants,
			queryParticipants: this.queryParticipants,
			confirmParticipant: this.confirmParticipant,
			fetchCostCenters: this.fetchCostCenters,
			removeCostCenter: this.removeCostCenter,
			fetchCities: this.fetchCities
		};

		return <BillingContext.Provider value={value}>{this.props.children}</BillingContext.Provider>;
	}
}

export default BillingProvider;
