import React, { Fragment } from 'react';
import { Card, CardBody, Col, Table, Button } from 'reactstrap';
import ModalParticipants from '../../Participants/components/ParticipantModal';
import { enableNotification } from '../../../../shared/notify';
import { BillingContext } from '../../../../context/Billing';

class LinkParticipants extends React.Component {
	notify = enableNotification('full');
	static contextType = BillingContext;

	state = {
		modal: false,
		participant: null,
	};

	register = async () => {
		this.setState({ modal: false });
		const result = await this.context.confirmParticipant(this.state.participant);

		if (result instanceof Error) {
			return this.notify(result.message, 'danger');
		}

		return this.notify('Participante cadastrado com sucesso.', 'success');
	};

	modalHandler = () => this.setState(prevState => ({ modal: !prevState.modal }));

	render() {
		const { supposedParticipants: participants } = this.context;

		return (
			<Fragment>
				<ModalParticipants
					cadastrar={this.register}
					participant={this.state.participant}
					modal={this.state.modal}
					handleModal={this.modalHandler}
					register
				/>
				<Col md={12} lg={12}>
					<Card style={{ marginTop: 30 }}>
						<CardBody>
							<Table responsive className="table--bordered">
								<thead>
									<tr>
										<th>#</th>
										<th>Nome</th>
										<th>Email</th>
										<th>Cidade</th>
										<th>Telefone</th>
										<th>Vincular</th>
									</tr>
								</thead>
								<tbody>
									{participants.map((participant, index) => {
										return (
											<tr key={participant._id}>
												<td>{index + 1}</td>
												<td>{`${participant.first_name} ${participant.last_name}`}</td>
												<td>{participant.email}</td>
												<td>{participant.city}</td>
												<td>{participant.phone}</td>
												<td>
													<Button
														size="sm"
														onClick={() => {
															this.setState({ modal: true, participant });
														}}
													>
														Cadastrar
													</Button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</CardBody>
					</Card>
				</Col>
			</Fragment>
		);
	}
}

export default LinkParticipants;
