import React, { useContext, useEffect } from 'react';
import { DashboardContext } from '../../../../context/Dashboard';
import Panel from '../../../../shared/components/Panel';
import { translate } from 'react-i18next';
import { Table } from 'reactstrap';

const TopProviders = React.memo(({ t }) => {
	const {
		state: { topProviders },
		action: { getTopProviders },
	} = useContext(DashboardContext);

	useEffect(() => {
		getTopProviders();
	}, []);

	const { list, isLoading } = topProviders;

	return (
		<Panel
			md={5}
			title="Top entregadores"
			subhead="Entregadores com a maior quantidade de viagens"
			refresh={isLoading}
		>
			<Table responsive striped size="sm" className="table dashboard__occupancy-table">
				<thead>
					<tr>
						<th>Nome</th>
						<th>Entregas</th>
					</tr>
				</thead>
				<tbody>
					{list.map((provider, index) => (
						<tr key={index}>
							<td className="td-gray">
								{provider.first_name} {provider.last_name}
							</td>
							<td className="td-blue">{provider.tripCount}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Panel>
	);
});

export default translate('common')(TopProviders);
