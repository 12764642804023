import React, { useContext, useState, useCallback, PureComponent, useEffect } from 'react';
// import DynamicForm from '../../../../shared/components/form/DynamicForm';
import { BillingContext } from '../../../../context/Billing';
import { Input, Label } from 'reactstrap';
// import { Redirect } from 'react-router-dom';
import { enableNotification } from '../../../../shared/notify';

const RegisterCostCenter = props => {
	const notify = enableNotification('full');

	const {
		cities , createCostCenter: _createCostCenter
	} = useContext(BillingContext);
	
	// useEffect = () => {
	// 	fetchCities();
	// }

	const createCostCenter = async data => {
		const result = await _createCostCenter(data);

		if (result instanceof Error) {
			return notify(result.message, 'danger');
		}
		props.history.push('/cost_centers');
		return notify(result, 'success');
	};

	const [state, setState] = useState({
		name: '',
		phone:'',
		city_id:'',
		address: '',
		location: null,
	});

	const onChange = useCallback(event => {
		const { value, name } = event.target;
		setState(prev => ({ ...prev, [name]: value }));
	}, []);

	const updateState = useCallback(data => {
		setState(prev => ({ ...prev, ...data }));
	}, []);

	const isDisabled = !state.name && !state.address && !Array.isArray(state.location);

	return (
		<div style={{ textAlign: 'left' }}>
			<div
				style={{
					marginTop: '0px',
					minWidth: '300px',
					width: '350px',
				}}
			>
				<Label>Nome</Label>
				<Input value={state.name} onChange={onChange} name="name" placeholder="Identificação da sua loja" />
			</div>
			<div
				style={{
					marginTop: '10px',
					minWidth: '300px',
					width: '350px',
				}}
			>
				<Label>Endereço</Label>
				<PlaceAutoComplete updateCallback={updateState} />
				{/* <Input value={state.address} onChange={onChange} name="address" /> */}
			</div>
			<div
				style={{
					marginTop: '10px',
					minWidth: '300px',
					width: '350px',
				}}
			>
				<Label>Cidade / Região</Label>
				<Input value={state.city_id} onChange={onChange} name="city_id" placeholder="Selecione" type="select" style={{height: 32,borderRadius: 0,borderColor: '#EEE', fontSize:13}}>
				<option value=''>Selecione:</option>
					{cities.map(c => {
						return <option value={c._id}>{c.full_cityname}</option>;
					})}
				</Input>
			</div>
			<div
				style={{
					marginTop: '10px',
					minWidth: '300px',
					width: '350px',
				}}
			>
				<Label>Telefone / Whatsapp</Label>
				<Input value={state.phone} onChange={onChange} name="phone" type="number" placeholder="DDD + Telefone (*somente numeros)" />
			</div>
			<button
				type="submit"
				style={{ marginTop: '20px', marginBottom:`0px` }}
				className="btn btn-primary account__btn"
				disabled={isDisabled}
				onClick={() => {
					createCostCenter(state);
				}}
			>
				Enviar
			</button>
		</div>
	);
};

const __googleMaps = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places&callback=init`;

class PlaceAutoComplete extends PureComponent {
	input = React.createRef();
	autoComplete = null;

	constructor(props) {
		super(props);

		window.init = this.startAutocompleteService;
	}

	componentDidMount() {
		if (window.google) {
			this.startAutocompleteService();
			return;
		}

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = __googleMaps;
		document.body.appendChild(script);
	}

	startAutocompleteService = () => {
		this.autocomplete = new window.google.maps.places.Autocomplete(this.input.current);
		this.autocomplete.setFields(['address_components', 'geometry', 'name']);
		this.autocomplete.addListener('place_changed', () => {
			const place = this.autocomplete.getPlace();

			if (!place.geometry) {
				window.alert('Selecione um local');
				return;
			}

			let address = this.input.current.value || place.name;

			const { lat, lng } = place.geometry.location.toJSON();

			this.props.updateCallback({ address, location: [lat, lng] });
		});
	};

	render() {
		return <input type="text" className="form-control" ref={this.input} />;
	}
}

export default RegisterCostCenter;
