import {
  ADD_PAYMENT_ACCOUNT,
  LOAD_PAYMENT_ACCOUNTS,
  SET_PAYMENT_ACCOUNT,
  CREATE_PAYMENT_ACCOUNT_HAS_ERRORED,
  RESET_PAYMENT_ACCOUNT_ERRORS,
} from '../actionTypes';

const DEFAULT_STATE = {
  paymentAccounts: [],
  paymentAccount: {},
  hasErrored: false,
  error: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_PAYMENT_ACCOUNT:
      return {
        ...state,
        paymentAccounts: [...state.paymentAccounts, action.newPaymentAccount],
        hasErrored: false,
        error: {},
        addPaymentAccountSuccess: true,
      };
    case LOAD_PAYMENT_ACCOUNTS:
      return {
        ...state,
        paymentAccounts: action.paymentAccountList,
        hasErrored: false,
        error: {},
        addPaymentAccountSuccess: false,
      };
    case SET_PAYMENT_ACCOUNT:
      return {
        ...state,
        paymentAccount: action.paymentAccount,
        error: {},
        hasErrored: false,
        addPaymentAccountSuccess: false,
      };
    case CREATE_PAYMENT_ACCOUNT_HAS_ERRORED:
      return {
        ...state,
        error: action.error,
        hasErrored: true,
        addPaymentAccountSuccess: false,
      };
    case RESET_PAYMENT_ACCOUNT_ERRORS:
      return {
        ...state,
        addPaymentAccountSuccess: false,
        error: {},
        hasErrored: false,
      };
    default:
      return state;
  }
};
