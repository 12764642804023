import React, { PureComponent } from 'react';
import { AuthContext } from '../../../context/Auth';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

// const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
	static contextType = AuthContext;

	constructor() {
		super();
		this.state = {
			collapse: false,
		};
	}

	logout = e => {
		e.preventDefault();
		this.context.logout();
	};

	toggle = () => {
		this.setState({ collapse: !this.state.collapse });
	};

	render() {
		const { user, isLoggedIn } = this.context;

		return isLoggedIn ? (
			<div className="topbar__profile">
				<button className="topbar__avatar" onClick={this.toggle}>
					<p className="topbar__avatar-name">{user.display_name}</p>
					<DownIcon className="topbar__icon" />
				</button>
				{this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
				<Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
					<div className="topbar__menu">
						<TopbarMenuLink title="Meu Perfil" icon="user" path="/account/profile" />
						{/* <TopbarMenuLink title="Calendar" icon="calendar-full" path="/default_pages/calendar" /> */}
						{/* <TopbarMenuLink title="Tasks" icon="list" path="/default_pages/calendar" /> */}
						{/* <TopbarMenuLink title="Inbox" icon="inbox" path="/mail" /> */}
						<div className="topbar__menu-divider" />
						{/* <TopbarMenuLink
              title="Configurações"
              icon="cog"
              path="/account/profile"
            /> */}
						{/* <TopbarMenuLink title="Lock Screen" icon="lock" path="/lock_screen" /> */}
						<TopbarMenuLink onClick={this.logout} path="#" title="Sair" icon="exit" />
					</div>
				</Collapse>
			</div>
		) : null;
	}
}

export default TopbarProfile;
