import React from 'react';
import { AuthContext } from './Auth';
import moment from 'moment';

export const TripsContext = React.createContext({});

class TripsProvider extends React.PureComponent {
	static contextType = AuthContext;

	initialState = {
		trips: [],
		tripsAreLoading: false,
		tripsHasErrored: false,
		tripInvoice: [],
		tripInvoiceLoading: false
	};

	state = this.initialState;

	fetchTrips = (method, date) => {
		const {
			request,
			user: { company },
		} = this.context;

		this.setState({ trips: [], tripsAreLoading: true }, async () => {
			try {
				if(date === undefined){
					date = {
						start: moment().startOf('isoWeek').format('Y-MM-DD'),
						end: moment().format('Y-MM-DD'),
					}
				}
				const requestMethod = date !== null ? 'post' : 'get';
				const response = await request(requestMethod, `api/company/v1/companies/${company}/trips`, {
					date,
				});

				if (response.data.success) {
					console.log(response.data.data.trips);
					this.setState({
						trips: response.data.data.trips,
						tripsAreLoading: false,
						tripsHasErrored: false,
					});
				}
			} catch (err) {
				this.setState({
					trips: [],
					tripsAreLoading: false,
					tripsHasErrored: true,
				});
			}
		});
	};

	fetchInvoiceTrip = (trips) => {
		const {
			request,
			user: { company },
		} = this.context;

		this.setState({ tripInvoice: [], tripInvoiceLoading: true }, async () => {
			try {
				for(let i = 0; i< trips.length; i++){ 
					let trip_id = trips[i]._id;
					const response = await request('get', `api/company/v1/companies/${company}/financial/invoce_trip/${trip_id}`);
					
					if (response.data.success) {
						this.setState({
							tripInvoice: response.data.data,
							tripInvoiceLoading: false
						});
						if(response.data.data.length) return;
					}
				}
			} catch (err) {
				this.setState({
					tripInvoice: [],
					tripInvoiceLoading: false
				});
			}
		});
	};



	render = () => {
		const value = {
			...this.state,
			fetchTrips: this.fetchTrips,
			fetchInvoiceTrip: this.fetchInvoiceTrip,
		};

		return <TripsContext.Provider value={value}>{this.props.children}</TripsContext.Provider>;
	};
}

export default TripsProvider;
