import {
  SET_CURRENT_USER,
  GET_CURRENT_USER_DATA_ERROR,
  CURRENT_USER_IS_LOADING,
  LOGOUT
} from "../actionTypes";
import { apiCall, setTokenHeader } from "../../lib/apiCall";

export const getCurrentUserData = () => async dispatch => {
  dispatch(currentUserIsLoading(true));

  const { jwtToken: jwt } = localStorage;

  if (!jwt) {
    dispatch(currentUserIsLoading(false));
    return dispatch(getCurrentUserDataHasErrored(true));
  }

  setAuthorizationToken(jwt);

  const res = await apiCall(
    "get",
    `${process.env.REACT_APP_BASE_URL}api/company/${process.env.REACT_APP_API_VERSION}/company_admins/me`
  );

  if (res.data) {
    dispatch(currentUserIsLoading(false));
    return dispatch(setCurrentUser(res.data));
  }

  dispatch(currentUserIsLoading(false));
  return dispatch(getCurrentUserDataHasErrored(true));
};

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user
  };
}

export function currentUserIsLoading(bool) {
  return {
    type: CURRENT_USER_IS_LOADING,
    bool
  };
}

export function getCurrentUserDataHasErrored(bool) {
  return {
    type: GET_CURRENT_USER_DATA_ERROR,
    bool
  };
}

const url = `${process.env.REACT_APP_BASE_URL}api/company/${process.env.REACT_APP_API_VERSION}/company_admins/signin`;

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function authUser(userData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return apiCall("post", url, userData)
        .then(({ data }) => {
          localStorage.setItem("jwtToken", data.token);
          setAuthorizationToken(data.token);
          dispatch(setCurrentUser(data.user));
          //remove error here
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}
export function logout() {
  return dispatch => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch({
      type: LOGOUT
    });
  };
}
