import React, { useContext, useEffect } from 'react';
import { BillingContext } from '../../../../context/Billing';
import { AuthContext } from '../../../../context/Auth';
import Description from './Description';
import CostCenterCard from './CostCenterCard';

import Error from '../../../../shared/components/Error';

const memoizedKeys = [...Array(12)].map((_, i) => i * Math.pow(i, 2) + '-' + i.toString());

const CostCentersScaffold = () => memoizedKeys.map(key => <div key={key} className="scaffold" />);

const CostCenters = () => {
	const { user } = useContext(AuthContext);

	const { costCenters, costCentersAreLoading, fetchCostCenters } = useContext(BillingContext);

	useEffect(() => {
		if (!!user) fetchCostCenters();
	}, [user]);

	return (
		<div className="cost-centers">
			<div className="cost-centers-cards">
				{costCentersAreLoading ? (
					<CostCentersScaffold />
				) : (
					costCenters.map(costCenter => (
						<CostCenterCard
							key={costCenter._id}
							id={costCenter._id}
							name={costCenter.name}
							phone={costCenter.phone}
							address={costCenter.address}
							createdAt={costCenter.created_at}
						/>
					))
				)}
			</div>

			{!costCentersAreLoading && costCenters.length === 0 && (
				<div className="error-message">
					<Error message="Esta conta de pagamento não possui centros de custo" />
				</div>
			)}
		</div>
	);
};

export default CostCenters;
