import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';
import { logout } from '../../../redux/actions/loginCompanyActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class SidebarContent extends Component {
	static propTypes = {
		changeToDark: PropTypes.func.isRequired,
		changeToLight: PropTypes.func.isRequired,
		onClick: PropTypes.func.isRequired,
	};

	hideSidebar = () => {
		this.props.onClick();
	};

	render() {
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					<SidebarLink title="Dashboard" icon="chart-bars" route="/dashboard_default" onClick={this.hideSidebar} />
					<SidebarLink title="Entregas" icon="location" route="/trips" onClick={this.hideSidebar} />
					<SidebarLink title="Faturas" icon="printer" route="/invoces" onClick={this.hideSidebar} />
				</ul>
				<ul className="sidebar__block">
					{/* <SidebarLink title="Financeiro" icon="briefcase" route="/financial" onClick={this.hideSidebar} /> */}
					<SidebarLink title="Lojas" icon="map-marker" route="/cost_centers" onClick={this.hideSidebar} />
				</ul>
			</div>
		);
	}
}

export default connect(
	null,
	{ logout }
)(withRouter(SidebarContent));
