/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { DashboardContext } from '../../../../context/Dashboard';
import { BarChart, Bar, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import PropTypes from 'prop-types';
import moment from 'moment';

const getDateFormated = dateString => {
	return moment(dateString).format('DD/MM/YYYY');
};

class Participants extends Component {
	static contextType = DashboardContext;

	static propTypes = {
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			activeItem: null,
		};
	}

	handleClick = (item, index) => {
		this.setState({
			activeItem: item,
			activeIndex: index,
		});
	};

	componentDidMount() {
		const { getParticipants } = this.context.action;
		getParticipants();
	}

	render() {
		const { activeItem, activeIndex } = this.state;
		const { list, total } = this.context.state.participants;
		const { t } = this.props;

		return (
			<Col md={12} xl={6} lg={6} xs={12}>
				<Card>
					<CardBody className="dashboard__card-widget">
						<div className="card__title">
							<h5 className="bold-text">{t('dashboard_default.participants')}</h5>
						</div>
						<div className="dashboard__total">
							<TrendingUpIcon className="dashboard__trend-icon" />
							<p className="dashboard__total-stat">{(activeItem && activeItem.uv) || total}</p>
							<ResponsiveContainer height={101} className="dashboard__chart-container">
								{!!list && (
									<BarChart data={list}>
										<Bar dataKey="uv">
											{list.map((entry, index) => (
												<Cell
													cursor="pointer"
													fill={index === activeIndex ? '#4ce1b6' : '#ff4861'}
													key={`cell-${index}`}
												/>
											))}
										</Bar>
										<Tooltip
											content={({ active, payload }) => {
												if (active) {
													return (
														<div className="custom-tooltip">
															<p className="label">
																<b>{`${payload ? getDateFormated(payload[0].payload.name) : ''}`}</b>
															</p>
															{payload ? payload[0].value : 0}
														</div>
													);
												}

												return null;
											}}
										/>
									</BarChart>
								)}
							</ResponsiveContainer>
						</div>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default translate('common')(Participants);
