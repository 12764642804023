import React, { PureComponent } from 'react';
import { DashboardContext } from '../../../../context/Dashboard';
import { BarChart, Bar, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';

const getDateFormated = dateString => {
	return moment(dateString).format('DD/MM/YYYY');
};

class Expenses extends PureComponent {
	static contextType = DashboardContext;

	static propTypes = {
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			activeItem: null,
		};
	}

	handleClick = (item, index) => {
		this.setState({
			activeIndex: index,
			activeItem: item,
		});
	};

	componentDidMount() {
		this.context.action.getExpenses();
	}

	render() {
		const { t } = this.props;
		const { list, total } = this.context.state.expenses;

		return (
			<Col md={12} xl={6} lg={6} xs={12}>
				<Card>
					<CardBody className="dashboard__card-widget">
						<div className="card__title">
							<h5 className="bold-text">{t('dashboard_default.expenses')}</h5>
						</div>
						<div className="dashboard__total">
							<TrendingDownIcon className="dashboard__trend-icon" />
							<p className="dashboard__total-stat">
								<NumberFormat
									value={total}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={'.'}
									decimalSeparator={','}
								/>
							</p>
							<ResponsiveContainer height={101} className="dashboard__chart-container">
								{!!list && (
									<BarChart data={list}>
										<Bar dataKey="pv">
											{list.map((entry, index) => (
												<Cell cursor="pointer" fill="#c88ffa" key={`cell-${index}`} />
											))}
										</Bar>
										<Tooltip
											content={({ active, payload }) => {
												if (active) {
													return (
														<div className="custom-tooltip">
															<p className="label">
																<b>{`${payload ? getDateFormated(payload[0].payload.name) : ''}`}</b>
															</p>
															<NumberFormat
																value={payload ? payload[0].value : 0}
																displayType={'text'}
																fixedDecimalScale={true}
																decimalScale={2}
																thousandSeparator={'.'}
																decimalSeparator={','}
															/>
														</div>
													);
												}
												return null;
											}}
										/>
									</BarChart>
								)}
							</ResponsiveContainer>
						</div>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default translate('common')(Expenses);
